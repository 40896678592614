import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import { Table, Button, Empty, Breadcrumb, Affix, message } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import CopyOutlined from "@ant-design/icons/CopyOutlined";

import "./Upload.scss";
import { getUploadsApi } from "../../../api/upload.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditUploadForm from "../../../components/Admin/Upload/EditUploadForm/EditUploadForm";

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export default function Upload() {
  const [uploads, setUploads] = useState([]);
  const token = getAccessTokenApi();
  const [reloadUploads, setReloadUploads] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getUploadsApi(token).then((response) => {
      setUploads(response.uploads);
      setLoadingData(false);
    });

    setReloadUploads(false);
  }, [token, reloadUploads]);

  const addUpload = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo archivo");
    setmodalContent(
      <EditUploadForm
        upload={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUploads={setReloadUploads}
        buttonName="Agregar archivo"
        addSlag={"add"}
        uploadType={"video/*,audio/*"}
      />
    );
  };

  const editUpload = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar archivo");
    setmodalContent(
      <EditUploadForm
        upload={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUploads={setReloadUploads}
        buttonName="Editar archivo"
        addSlag={"edit"}
        uploadType={"video/*,audio/*"}
      />
    );
  };

  const deleteUpload = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar archivo");
    setmodalContent(
      <EditUploadForm
        upload={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUploads={setReloadUploads}
        buttonName="Eliminar archivo"
        addSlag={"delete"}
        uploadType={"video/*,audio/*"}
      />
    );
  };

  const copyUpload = (record) => {
    copyTextToClipboard(record.url);
    message.success("URL copiado en el portapapeles!");
  };

  const columns = [
    {
      title: "Código",
      width: 100,
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Url",
      width: 150,
      dataIndex: "url",
      key: "url",
    },

    {
      title: "Usuario",
      width: 100,
      //dataIndex: ["user", "name"],
      key: "user",
      render: (text, record) => (
        <span>
          {record.user ? record.user.name : ""}{" "}
          {record.user ? record.user.lastname : ""}
        </span>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => editUpload(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => copyUpload(record)}
          >
            <CopyOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteUpload(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="upload">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Parámetros globales</Breadcrumb.Item>
        <Breadcrumb.Item>Archivos</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addUpload}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={uploads}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen archivos"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
