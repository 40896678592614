import React, { useState } from "react";
import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Writer/MenuTop";
import MenuSider from "../components/Writer/MenuSider";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";
import WriterHome from "../pages/Writer";
import WriterArticles from "../pages/Writer/Articulos";
import WriterNotification from "../pages/Writer/Notificacion";
import AdminSignIn from "../pages/Admin/SignIn";
import WriterExclusives from "../pages/Writer/Exclusivos";
import WriterFiles from "../pages/Writer/Upload";

import "./LayoutWriter.scss";
import useAuth from "../hooks/useAuth";

export default function LayoutWriter(props) {
  //const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;

  const { user, isLoading } = useAuth(); //hook
  const navigate = useNavigate();

  console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 3) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    //navigate("/user");
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} />
        <Layout
          className="layout-writer"
          // style={{ marginLeft: menuCollapsed ? "80px" : "200px" }}
        >
          <Header className="layout-writer__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/escritor" element={<WriterHome />} />
              {/* <Route path="/admin/login" element={<WriterSignIn />} /> */}
              <Route
                path="/escritor/articulos/listado"
                element={<WriterArticles />}
              />
              <Route
                path="/escritor/articulos/notificacion"
                element={<WriterNotification />}
              />
              <Route
                path="/escritor/articulos/archivos"
                element={<WriterFiles />}
              />
              <Route
                path="/escritor/articulos/exclusivos"
                element={<WriterExclusives />}
              />
            </Routes>
          </Content>
          <Footer className="layout-admin__footer">
            OTR - 2024 COPYRIGHT©
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes({ routes }) {
//   return (
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           //component={route.component}
//           element={<route.component />}
//         />
//       ))}
//     </Routes>
//   );
// }
