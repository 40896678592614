import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  // InputNumber,
  // Radio,
  // DatePicker,
  Upload,
  message,
  Tag,
  Tooltip,
  Switch,
} from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateArticleApi,
  AddArticleApi,
  DeleteArticleApi,
} from "../../../../api/article.api";
//import useAuth from "../../../../../hooks/useAuth";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./EditArticleForm.scss";
//import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import moment from "moment";
import SelectTypeForm from "../../../../components/Admin/Type/SelectTypeForm";

import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";
import "./quill.snow.css";

import useAuth from "../../../../hooks/useAuth";

import { PropTypes } from "prop-types";

import ModalUser from "../../ModalUser/ModalArticleUser";
import EditUploadForm from "../../Upload/EditUploadForm/EditUploadArticleForm";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/*
 * Simple editor component that takes placeholder text as a prop
 */

const Quill = ReactQuill.Quill;
const BlockEmbed = Quill.import("blots/block/embed");
const Font = Quill.import("formats/font");
Font.whitelist = [
  // "sans serif",
  // "serif",
  // "monospace",
  "Gothambook",
  "Gothamultra",
  "Gothamlight",
  "Gothammedium",
  "Ibarra",
  "Ibarrabold",
];
Quill.register(Font, true);
//const Inline = Quill.import("blots/inline");
class AudioBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
}
AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";
Quill.register(AudioBlot);

class VideoBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
}
VideoBlot.blotName = "videoupload";
VideoBlot.tagName = "video";
Quill.register(VideoBlot);

class LinkBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.innerText = url.value;
    node.setAttribute("href", url.url);
    node.setAttribute("target", "_blank");
    return node;
  }
  // static formats(node) {
  //   return node.getAttribute("href");
  // }
  static value(node) {
    return node.getAttribute("href");
  }
}
LinkBlot.blotName = "linkupload";
LinkBlot.tagName = "a";
Quill.register(LinkBlot);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      mountedEditor: false,
      isVisibleUploadModal: false,
      modalTitle: "Agregar nuevo archivo",
      modalContent: null,
      uploadUrl: "",
    };
    this.quillRef = null;
    this.reactQuillRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickVideo = this.handleClickVideo.bind(this);
    this.handleClickPdf = this.handleClickPdf.bind(this);
    this.attachQuillRefs = this.attachQuillRefs.bind(this);
    this.update = this.update.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.updateUrlVideo = this.updateUrlVideo.bind(this);
    this.updateUrlPdf = this.updateUrlPdf.bind(this);
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  }

  update(nextState) {
    //console.log(nextState);
    this.setState(nextState);
  }

  updateUrl(url, range) {
    this.attachQuillRefs();
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //console.log(this.quillRef);
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "audio", url, "user");
  }

  handleClick(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();

    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrl}
          buttonName="Agregar archivo de audio"
          addSlag={"add"}
          uploadType={"audio/mp3,audio/*;"}
          range={range}
        />
      ),
    });
  }

  updateUrlVideo(url, range) {
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "videoupload", url, "user");
  }

  handleClickVideo(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();
    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrlVideo}
          buttonName="Agregar archivo de video"
          addSlag={"add"}
          uploadType={"video/*"}
          range={range}
        />
      ),
    });
  }

  updateUrlPdf(url, range) {
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "linkupload", url, "user");
  }

  handleClickPdf(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();
    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrlPdf}
          buttonName="Agregar documento"
          addSlag={"add"}
          uploadType={"application/pdf"}
          range={range}
        />
      ),
    });
  }

  handleChange(html) {
    this.props.setEditor({ editorHtml: html });
    this.setState({ editorHtml: html });
  }

  render() {
    return (
      <div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme={"snow"}
          onChange={this.handleChange}
          modules={Editor.modules}
          formats={Editor.formats}
          value={this.props.editor.editorHtml}
          //defaultValue={this.state.editorHtml}
          //value={this.state.editorHtml}
          placeholder={"Aquí el contenido del artículo..."}
        />
        <button type="primary" onClick={this.handleClick}>
          Insertar audio
        </button>
        <button type="primary" onClick={this.handleClickVideo}>
          Insertar video
        </button>
        <button type="primary" onClick={this.handleClickPdf}>
          Insertar documento
        </button>
        <ModalUser
          title={this.state.modalTitle}
          isVisibleModal={this.state.isVisibleUploadModal}
          setIsVisibleModal={this.update}
        >
          {this.state.modalContent}
        </ModalUser>
      </div>
    );
  }
}

Editor.modules = {};
Editor.modules.toolbar = [
  // ["bold", "italic", "underline", "strike"], // toggled buttons
  // ["blockquote", "code-block"], // blocks
  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: "ordered" }, { list: "bullet" }], // lists
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  // [{ color: [] }, { background: [] }], // dropdown with defaults
  // [{ font: [] }], // font family
  // [{ align: [] }], // text align
  // ["clean"], // remove formatting
  //[{ header: "1" }, { header: "2" }, { font: [] }],
  [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],

  [{ size: [] }],
  // ["bold", "italic", "underline", "strike", "blockquote"],
  ["bold", "italic", "underline", "strike"],
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
  "audio",
  "videoupload",
  "linkupload",
];

Editor.propTypes = {
  placeholder: PropTypes.string,
  editor: PropTypes.object,
  setEditor: function (props, propname, componentname) {
    //const prop = props[propname];
    // console.log(props.editor);
    // console.log(propname);
    // console.log(componentname);
    this.setState(props.editor);
    this.attachQuillRefs();
  },
  // title: PropTypes.string,
  // isVisibleModal: PropTypes.bool,
  // setIsVisibleModal: function (props, propname, componentname) {
  //   //const prop = props[propname];
  // },
  // modalContent: PropTypes.element,
};

export default function EditArticleForm(props) {
  const {
    article,
    setIsVisibleModal,
    setReloadArticles,
    buttonName,
    addSlag,
    date,
  } = props;
  const { user, isLoading } = useAuth();

  const [articleData, setArticleData] = useState({});
  const token = getAccessTokenApi();
  const [formArticle] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const urlclient = window.location.origin;
  const [type, setType] = useState();

  // const [tags, setTags] = useState(["Unremovable", "Tag 2", "Tag 3"]);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const [editor, setEditor] = useState({ editorHtml: "", theme: "snow" });

  // const [isVisibleUploadModal, setIsVisibleUploadModal] = useState(false);
  // const [modalTitle, setModalTitle] = useState();
  // const [modalContent, setmodalContent] = useState();
  // const [reloadUploads, setReloadUploads] = useState(false);
  // const [uploadUrl, setUploadUrl] = useState("");

  // const quillRef = useRef();

  // const pathname = window.location.pathname;
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;
  // const Quill = ReactQuill.Quill;
  // const BlockEmbed = Quill.import("blots/block/embed");
  // class AudioBlot extends BlockEmbed {
  //   static create(url) {
  //     let node = super.create();
  //     node.setAttribute("src", url);
  //     node.setAttribute("controls", "");
  //     return node;
  //   }

  //   static value(node) {
  //     return node.getAttribute("src");
  //   }
  // }
  // AudioBlot.blotName = "audio";
  // AudioBlot.tagName = "audio";
  // Quill.register(AudioBlot);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo se pueden cargar imágenes en JPG/PNG!");
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error("La imagen tiene que ser menor a 4MB!");
    }
    if (isJpgOrPng && isLt2M) {
      //console.log(file);
      getBase64(file, (url) => {
        setLoading(false);
        setImageUrl(url);
        //console.log(url);
      });
    }
    return false;
    // return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Cargar imagen principal
      </div>
    </div>
  );

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    // setArticleData({
    //   code: "",
    //   name: "",
    // });
  };

  useEffect(() => {
    if (article) {
      if (article.date && article.date !== undefined) {
        article.date = moment(article.date);
      }
      if (article.key !== null) {
        var tagsload = article.key.split(",");
        // console.log(article.key);
        // console.log(tagsload);
        if (tagsload.length > 0) {
          setTags(tagsload);
        }
      }
      setEditor({ editorHtml: article.description });
      setImageUrl(article.photo);
      formArticle.setFieldsValue(article);
    } else {
      setEditor({ editorHtml: "" });
      setImageUrl(null);
      setTags([]);
      formArticle.setFieldsValue({
        title: null,
        key: null,
        summary: null,
        status: 1,
        description: null,
        observations: null,
        date: null,
        photo: null,
        typeId: null,
        userId: null,
      });
    }

    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [article]);

  useEffect(() => {
    console.log(addSlag);
    if (addSlag === "add") {
      setImageUrl(null);
      setTags([]);
      setEditor({ editorHtml: "" });
      formArticle.setFieldsValue({
        title: null,
        key: null,
        summary: null,
        status: 1,
        description: null,
        observations: null,
        date: null,
        photo: null,
        typeId: null,
        userId: null,
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [date]);

  const onFinish = (values) => {
    if (imageUrl === undefined || imageUrl === null || imageUrl.length === 0) {
      notification["error"]({
        message: "Se requiere cargar una imagen principal",
      });
      return;
    }

    if (editor.editorHtml.length === 0) {
      notification["error"]({
        message: "Se requiere ingresar información en el artículo",
      });
      return;
    }
    setSending(true);
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddArticleApi(token, {
        ...values,
        description: editor.editorHtml,
        photo: imageUrl,
        // key: tags.length > 0 ? tags.toString() : null,
        key:
          tags.length > 0
            ? tags.map((item) => `${item.trim()}`).join(", ")
            : null,
        userId: user.id,
        //description: editor.editorHtml,
      }).then((result) => {
        if (result.status) {
          notification["success"]({
            message: result.message,
          });
          setSending(false);
          setReloadArticles(true);
          setIsVisibleModal(false);
        } else {
          setSending(false);
          notification["error"]({
            message: result.message,
          });
        }

        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateArticleApi(
        token,
        {
          ...values,
          description: editor.editorHtml,
          // key: tags.length > 0 ? tags.toString() : null,
          key:
            tags.length > 0
              ? tags.map((item) => `${item.trim()}`).join(", ")
              : null,
          photo: imageUrl,
          //userId: user.id,
        },
        article.id
      ).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadArticles(true);
        setIsVisibleModal(false);
        setArticleData({
          ...articleData,
        });
      });
    } else {
      DeleteArticleApi(token, article.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadArticles(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    console.log(imageUrl);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  // const handleChangeEditor = (html) => {
  //   setEditor({ editorHtml: html });
  // };

  // const addUpload = () => {
  //   setIsVisibleUploadModal(true);
  //   setModalTitle("Agregar nuevo archivo");
  //   setmodalContent(
  //     <EditUploadForm
  //       upload={null}
  //       setIsVisibleModal={setIsVisibleUploadModal}
  //       setReloadUploads={setReloadUploads}
  //       setUrl={setUploadUrl}
  //       buttonName="Agregar archivo"
  //       addSlag={"add"}
  //       uploadType={"audio/mp3,audio/*;"}
  //     />
  //   );
  // };

  return (
    <div className="edit-article-form">
      <Form
        form={formArticle}
        name="article"
        className="form-edit"
        //onSubmit={addUpdateArticle}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={6} lg={4} xl={3}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              //action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              //onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={18} lg={20} xl={21}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                <Form.Item
                  label="Título del artículo"
                  name={"title"}
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el título del artículo",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Título del artículo"
                    maxLength={300}
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                <SelectTypeForm
                  atributoData={type}
                  setAtributoData={setType}
                ></SelectTypeForm>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Form.Item
                  label="Articulo visible"
                  name={"status"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <>
                  {tags.map((tag, index) => {
                    if (editInputIndex === index) {
                      return (
                        <Input
                          ref={editInputRef}
                          key={tag}
                          size="small"
                          className="tag-input"
                          value={editInputValue}
                          onChange={handleEditInputChange}
                          onBlur={handleEditInputConfirm}
                          onPressEnter={handleEditInputConfirm}
                        />
                      );
                    }
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        className="edit-tag"
                        key={tag}
                        // closable={index !== 0}
                        closable={true}
                        onClose={() => handleClose(tag)}
                      >
                        <span
                          onDoubleClick={(e) => {
                            if (index !== 0) {
                              setEditInputIndex(index);
                              setEditInputValue(tag);
                              e.preventDefault();
                            }
                          }}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                  {inputVisible && (
                    <Input
                      ref={inputRef}
                      type="text"
                      size="small"
                      className="tag-input"
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                    />
                  )}
                  {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={showInput}>
                      <PlusOutlined /> Palabra clave
                    </Tag>
                  )}
                </>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Descripción corta"
              name={"description"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción corta",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TextArea
                //prefix={<UserOutlined />}
                rows={2}
                placeholder="Descripción corta"
                maxLength={1200}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Resumen"
              name={"summary"}
              rules={[
                {
                  required: true,
                  message: "Ingrese un resumen",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TextArea
                //prefix={<UserOutlined />}
                rows={2}
                placeholder="Resumen"
                maxLength={1200}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Observaciones"
              name={"observations"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Ingrese una observación",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TextArea
                //prefix={<UserOutlined />}
                rows={2}
                placeholder="Observaciones"
                maxLength={1200}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Artículo"
              name={"description"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Ingrese el artículo",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              {/* <ReactQuill
                // ref={(el) => {
                //   quillRef.current = el;
                // }}
                //forwardedRef={quillRef}
                ref={quillRef}
                theme={editor.theme}
                onChange={handleChangeEditor}
                value={editor.editorHtml}
                modules={modules}
                formats={formats}
                bounds={".app"}
                placeholder={"Aquí el contenido del artículo"}
              /> */}
              <Editor
                placeholder={"Aquí el contenido del artículo..."}
                editor={editor}
                setEditor={setEditor}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Button type="primary" onClick={addUpload}>
              Agregar audio
            </Button>
          </Col> */}
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
            loading={sending}
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
