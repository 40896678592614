import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Card,
  Row,
  Col,
  Avatar,
  PageHeader,
  Tag,
  Button,
} from "antd";
import { Bar, Column } from "@ant-design/charts";
import { UserOutlined, HighlightOutlined } from "@ant-design/icons";

//import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";
import { getExtrasCountApi } from "../../api/extra.api";
import { getCountArticlesApi } from "../../api/article.api";

import { getLogGroupApi } from "../../api/log.api";

import { getAccessTokenApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
// import fincaSvg from "../../assets/img/svg/finca.svg";
// import L from "leaflet";
//import { getUsersInfoApi } from "../../api/user.api";

import ModalUser from "../../components/Admin/ModalUser";
import "./Admin.scss";

import interest from "../../assets/img/png/nointeresa.png";
import deep from "../../assets/img/png/profundizar.png";
import reunion from "../../assets/img/png/reunion.png";
import info from "../../assets/img/png/informacion.png";

export default function Admin({ socket }) {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  // const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  // const [potentials, setPotentials] = useState();
  // const [potentialsNeed, setPotentialsNeed] = useState();
  const { user, isLoading } = useAuth(); //hook
  //const [userinfo, setUserinfo] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();

  const [countInterest, setCountInterest] = useState();
  const [countDeep, setCountDeep] = useState();
  const [countInfo, setCountInfo] = useState();
  const [countReunion, setCountReunion] = useState();
  const [types, setTypes] = useState([]);
  const [countArticle, setCountArticle] = useState();
  // const [updateInfo, setUpdateInfo] = useState(false);
  // const [groupHave, setGroupHave] = useState();
  // const [groupNeed, setGroupNeed] = useState();
  // const [disabled, setDisabled] = useState(true);
  // const [need, setNeed] = useState([]);
  // const [maxactor, setMaxactor] = useState([]);
  // const [maxresidual, setMaxresidual] = useState([]);
  // const [maxresidualgroup, setMaxresidualgroup] = useState([]);
  // const [types, setTypes] = useState([]);
  // const [province, setProvince] = useState(0);
  const { Meta } = Card;

  //const [countEncuestas, setCountEncuestas] = useState();
  //const [fincas, setFincas] = useState([]);
  // useEffect(() => {
  //   getUsersInfoApi(token, user.id).then((response) => {
  //     //console.log(response);
  //     setUserinfo(response.user);
  //   });
  // }, [token, updateInfo]);

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });
    setLoadingData(true);

    // getUsersInfoApi(token, user.id).then((response) => {
    //   //console.log(response);
    //   setUserinfo(response.user);
    // });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getCountArticlesApi(token).then((response) => {
      setCountArticle(response.count);
    });

    getExtrasCountApi(token, {
      interest: true,
      deep: false,
      reunion: false,
      information: false,
    }).then((response) => {
      console.log(response);
      setCountInterest(response.count);
    });

    getExtrasCountApi(token, {
      interest: false,
      deep: true,
      reunion: false,
      information: false,
    }).then((response) => {
      console.log(response);
      setCountDeep(response.count);
    });

    getExtrasCountApi(token, {
      interest: false,
      deep: false,
      reunion: true,
      information: false,
    }).then((response) => {
      console.log(response);
      setCountReunion(response.count);
    });

    getExtrasCountApi(token, {
      interest: false,
      deep: false,
      reunion: false,
      information: true,
    }).then((response) => {
      console.log(response);
      setCountInfo(response.count);
    });

    getLogGroupApi(token).then((response) => {
      const data2 = [];
      console.log(response.logs);
      let logs1 = response.logs;
      logs1.map((log) =>
        data2.push({
          type: log.type,
          count: parseInt(log.count),
        })
      );

      setTypes(data2);
      //setLoadingH(false);
    });

    // getCountUsersApi(token, false).then((response) => {
    //   setCountUserInactive(response.count);
    // });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, [token]);

  // const iconFinca = new L.Icon({
  //   iconUrl: fincaSvg,
  //   iconRetinaUrl: fincaSvg,
  //   iconSize: [64, 64],
  //   iconAnchor: [32, 64],
  //   popupAnchor: [0, -46],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  const config3 = {
    xField: "count",
    yField: "type",
    seriesField: "type",
    legend: {
      position: "top-left",
    },
  };

  return (
    <>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        closable={false}
        width={1400}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header left"
        title="Off The Record"
        //subTitle="Ecosistema sostenible en Ecuador"
        //tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        breadcrumb={
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#a99215",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <img
                    className="menu-top__left-logo"
                    src={interest}
                    alt="interes"
                    style={{ width: "60px" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countInterest}</h2>
                  </Avatar>
                }
                title="Interés"
                description="Personas no les interesa"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#4096FF",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <img
                    className="menu-top__left-logo"
                    src={deep}
                    alt="profundizar"
                    style={{ width: "60px" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countDeep}</h2>
                  </Avatar>
                }
                title="Profundizar"
                description="Quieren profundizar"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#52C41A",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <img
                    className="menu-top__left-logo"
                    src={info}
                    alt="info"
                    style={{ width: "60px" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countInfo}</h2>
                  </Avatar>
                }
                title="Información"
                description="Tienen más información"
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#F5222D",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <img
                    className="menu-top__left-logo"
                    src={reunion}
                    alt="reunion"
                    style={{ width: "60px" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countReunion}</h2>
                  </Avatar>
                }
                title="Reunión"
                description="Solicitan reunión urgente"
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card
                  cover={
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "#C0C0C0",
                        padding: "30px 20px 30px 20px",
                      }}
                    >
                      <UserOutlined
                        style={{ fontSize: "40px", color: "white" }}
                      />
                    </div>
                  }
                >
                  <Meta
                    avatar={
                      <Avatar style={{ backgroundColor: "grey" }} size={44}>
                        <h2 style={{ color: "white" }}>{countUserActive}</h2>
                      </Avatar>
                    }
                    title="Usuarios"
                    description="Total registrados"
                  />
                </Card>
              </Col>
            </Row>
            <br />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card
                  cover={
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "#606060",
                        padding: "30px 20px 30px 20px",
                      }}
                    >
                      <HighlightOutlined
                        style={{ fontSize: "40px", color: "white" }}
                      />
                    </div>
                  }
                >
                  <Meta
                    avatar={
                      <Avatar style={{ backgroundColor: "grey" }} size={44}>
                        <h2 style={{ color: "white" }}>{countArticle}</h2>
                      </Avatar>
                    }
                    title="Artículos"
                    description="Total creados"
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={16} lg={18} xl={18}>
            <Card title={<span>Categorías más leídas</span>}>
              <Bar
                data={types ?? []}
                {...config3}
                // onReady={(plot) => {
                //   ref.current = plot;
                //   setDisabled(false);
                // }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
