import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Tabs,
  PageHeader,
  Switch,
} from "antd";
import { useDropzone } from "react-dropzone";
import noAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  SaveOutlined,
  UserOutlined,
  ToolOutlined,
  LockOutlined,
} from "@ant-design/icons";
import moment from "moment";

import {
  getAvatarApi,
  UploadAvatarApi,
  UpdateUserApi,
  AddUserApi,
} from "../../../../api/user.api";

import { getAccessTokenApi } from "../../../../api/auth";

import "./EditUserForm.scss";
import SelectRoleForm from "../../Role/SelectRoleForm/SelectRoleForm";

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers, addSlag } = props;
  const [avatar, setAvatar] = useState();
  const [userData, setUserData] = useState({});
  const { TabPane } = Tabs;
  const [buttonTitle, setbuttonTitle] = useState("");
  const [formPersonal] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const token = getAccessTokenApi();
  const [role, setRole] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingsMap, setLoadingsMap] = useState(true);

  const onChange = (key) => {
    console.log(key);
  };

  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, photo: avatar.file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  useEffect(() => {
    if (user && user.photo) {
      getAvatarApi(user.photo).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setbuttonTitle("Guardar cambios");
      setUserData(user);
      formPersonal.setFieldsValue(user);
      if (loadingsMap) {
        setLoadingsMap(false);
      }
      setRole(user.roleId);
      setLoadingData(false);
    } else {
      setbuttonTitle("Agregar usuario");
      setUserData({});
      formPersonal.setFieldsValue({
        id: null,
        name: "",
        lastname: "",
        ci: "",
        email: "",
        phone: "",
        roleId: null,
        photo: "",
        register: moment(),
        active: true,
        changepass: true,
        premium: false,
        passwordrepeat: "",
        password: "",
      });
    }
  }, [user]);
  //console.log(userData.admission == null);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      ...values,
    };
    setUserData(userAdd);
    console.log(userAdd);
    if (addSlag === "add") {
      AddUserApi(userAdd, token).then((result) => {
        if (result.status) {
          notification["success"]({
            message: result.message,
          });
          if (userAdd.photo && result.user) {
            console.log(userAdd);
            console.log(result);

            UploadAvatarApi(token, userAdd.photo, result.user.id).then(
              (response) => {
                userAdd.photo = response.avatarName;
                UpdateUserApi(token, userAdd, result.user.id).then(
                  (result2) => {
                    notification["success"]({
                      message: "Foto cargada correctamente",
                    });
                    setReloadUsers(true);
                    setIsVisibleModal(false);
                    setLoadings(false);
                    setUserData({
                      id: null,
                      name: "",
                      lastname: "",
                      ci: "",
                      email: "",
                      phone: "",
                      roleId: null,
                      photo: "",
                      register: moment(),
                      active: true,
                      changepass: true,
                      premium: false,
                      passwordrepeat: "",
                      password: "",
                    });
                    formPersonal.setFieldsValue({
                      id: null,
                      name: "",
                      lastname: "",
                      ci: "",
                      email: "",
                      phone: "",
                      roleId: null,
                      photo: "",
                      register: moment(),
                      active: true,
                      changepass: true,
                      premium: false,
                      passwordrepeat: "",
                      password: "",
                    });
                    // setUserData({
                    //   ...userData,
                    //   password: null,
                    //   repeatPassword: null,
                    // });
                  }
                );
              }
            );
          } else {
            // notification["success"]({
            //   message: result.message,
            // });
            setReloadUsers(true);
            setIsVisibleModal(false);
            setLoadings(false);
            setUserData({
              id: null,
              name: "",
              lastname: "",
              ci: "",
              email: "",
              phone: "",
              roleId: null,
              photo: "",
              register: moment(),
              active: true,
              changepass: true,
              premium: false,
              passwordrepeat: "",
              password: "",
            });
            formPersonal.setFieldsValue({
              id: null,
              name: "",
              lastname: "",
              ci: "",
              email: "",
              phone: "",
              roleId: null,
              photo: "",
              register: moment(),
              active: true,
              changepass: true,
              premium: false,
              passwordrepeat: "",
              password: "",
            });
          }
        } else {
          setLoadings(false);
          notification["error"]({
            message: result.message,
          });
        }
      });
    } else {
      UpdateUserApi(token, userAdd, user.id).then((result) => {
        if (result.status) {
          setUserData(result.user);
          notification["success"]({
            message: "La información se ha actualizado correctamente",
          });
          setReloadUsers(true);

          setLoadings(false);
          setIsVisibleModal(false);
        } else {
          notification["error"]({
            message: result.message,
          });
          setLoadings(false);
        }
      });
    }
    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información personal",
      duration: 3,
    });
  };

  const addUpdateUser = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let userUpdate = userData;

    if (!userUpdate.name || !userUpdate.email || !userUpdate.username) {
      notification["error"]({
        message: "El nombre, email y usuario es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddUserApi(token, userUpdate).then((result) => {
        if (result.status) {
          notification["success"]({
            message: result.message,
          });
          if (userUpdate.photo && result.user) {
            console.log(userUpdate);
            console.log(result);

            UploadAvatarApi(token, userUpdate.photo, result.user.id).then(
              (response) => {
                userUpdate.photo = response.avatarName;
                UpdateUserApi(token, userUpdate, result.user.id).then(
                  (result2) => {
                    notification["success"]({
                      message: "Foto cargada correctamente",
                    });
                    setReloadUsers(true);
                    setIsVisibleModal(false);
                    setUserData({
                      id: null,
                      name: "",
                      lastname: "",
                      ci: "",
                      email: "",
                      phone: "",
                      roleId: null,
                      photo: "",
                      register: moment(),
                      active: true,
                      changepass: true,
                      premium: false,
                    });
                    // setUserData({
                    //   ...userData,
                    //   password: null,
                    //   repeatPassword: null,
                    // });
                  }
                );
              }
            );
          } else {
            // notification["success"]({
            //   message: result.message,
            // });
            setReloadUsers(true);
            setIsVisibleModal(false);
            setUserData({
              id: null,
              name: "",
              lastname: "",
              ci: "",
              email: "",
              phone: "",
              roleId: null,
              photo: "",
              register: moment(),
              active: true,
              changepass: true,
              premium: false,
            });
          }
        } else {
          notification["error"]({
            message: result.message,
          });
        }
      });
    } else if (addSlag === "edit") {
      if (userUpdate.photo) {
        UploadAvatarApi(token, userUpdate.photo, user.id).then((response) => {
          userUpdate.photo = response.avatarName;
          UpdateUserApi(token, userUpdate, user.id).then((result) => {
            notification["success"]({
              message: result.message,
            });

            setIsVisibleModal(false);
            setReloadUsers(true);
            // setUserData({
            //   ...userData,
            //   password: null,
            //   repeatPassword: null,
            // });
          });
        });
      } else {
        UpdateUserApi(token, userUpdate, user.id).then((result) => {
          notification["success"]({
            message: result.message,
          });

          setIsVisibleModal(false);
          setReloadUsers(true);
          // setUserData({
          //   ...userData,
          //   password: null,
          //   repeatPassword: null,
          // });
        });
      }
    }
  };

  return (
    <div className="edit-user-form">
      <Form
        form={formPersonal}
        name="personal"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <UploadAvatar avatar={avatar} setAvatar={setAvatar} />
            {/* <PageHeader
              className="site-page-header header-left"
              // onBack={() => null}
              style={{ textAlign: "left", justifyContent: "start" }}
              title={
                <>
                  <Avatar
                    icon={<ToolOutlined />}
                    style={{ backgroundColor: "#f42c1e", color: "white" }}
                  />
                  <span> Rol</span>
                </>
              }
              // subTitle="This is a subtitle"
            /> */}
          </Col>
          <Col xs={24} sm={24} md={16} lg={18} xl={18}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <SelectRoleForm
                  atributoData={role}
                  setAtributoData={setRole}
                ></SelectRoleForm>
              </Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                <Form.Item
                  label="Premium"
                  name={"premium"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                <Form.Item
                  label="Activo"
                  name={"active"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <PageHeader
              className="site-page-header header-left"
              // onBack={() => null}
              style={{ textAlign: "left", justifyContent: "start" }}
              title={
                <>
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#f42c1e", color: "white" }}
                  />
                  <span> Datos personales</span>
                </>
              }
              // subTitle="This is a subtitle"
            />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item
                  label="Cédula"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  name="ci"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la cédula de identidad",
                    },
                    {
                      type: "string",
                      min: 10,
                      message: "La cédula de identidad no es correcta",
                    },
                  ]}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Cédula de identidad"
                    //value={userData.name}
                    maxLength={10}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={9} xl={9}>
                <Form.Item
                  label="Nombres"
                  name="name"
                  rules={[{ required: true, message: "Ingrese los nombres" }]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Nombres completos"
                    maxLength={50}
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={9} xl={9}>
                <Form.Item
                  label="Apellidos"
                  name="lastname"
                  rules={[{ required: true, message: "Ingrese los apellidos" }]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Apellidos completos"
                    //value={userData.name2}
                    maxLength={50}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name2: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Correo"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el correo electrónico",
                    },
                    {
                      type: "email",
                      message: "El correo ingresado no es correcto",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Correo electrónico"
                    //value={userData.name}
                    //disabled
                    maxLength={50}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item
                  label="Teléfono"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese su número de teléfono",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Teléfono/celular"
                    //value={userData.name2}
                    maxLength={10}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name2: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} lg={7} xl={7}>
            <Form.Item
              label="Centro de estudio"
              name="university"
              rules={[
                {
                  required: true,
                  message:
                    "Ingrese el centro de estudio - Universidad - Tecnología",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Centro de estudio - Universidad - Tecnología"
                maxLength={50}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={7}>
            <Form.Item
              label="Carrera cursada"
              name="career"
              rules={[
                {
                  required: true,
                  message: "Ingrese la carrera cursada",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Carrera cursada"
                maxLength={50}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={5} xl={4}>
            <Form.Item
              label="Año de graduación"
              name="year"
              // rules={[
              //   {
              //     required: true,
              //     message: "Ingrese el año de graduación",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <InputNumber
                //prefix={<UserOutlined />}
                placeholder="Año de graduación"
                maxLength={4}
                //value={userData.name}
                //defaultValue={1999}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}
            </Row>

            <PageHeader
              className="site-page-header header-left"
              // onBack={() => null}
              style={{ textAlign: "left", justifyContent: "start" }}
              title={
                <>
                  <Avatar
                    icon={<LockOutlined />}
                    style={{ backgroundColor: "#f42c1e", color: "white" }}
                  />
                  <span> Contraseña</span>
                </>
              }
              // subTitle="This is a subtitle"
            />
            <Row gutter={24}>
              {addSlag === "add" && (
                <>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Contraseña"
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: "Ingrese la contraseña",
                        },
                      ]}
                      wrapperCol={{ span: 24 }}
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        //prefix={<UserOutlined />}
                        placeholder="Contraseña"
                        type="password"
                        //value={userData.name}
                        onChange={
                          (e) => {}
                          //setUserData({ ...userData, name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Repetir contraseña"
                      name={"passwordrepeat"}
                      rules={[
                        {
                          required: true,
                          message: "Ingrese la contraseña",
                        },
                      ]}
                      wrapperCol={{ span: 24 }}
                      labelCol={{ span: 24 }}
                    >
                      <Input
                        //prefix={<UserOutlined />}
                        placeholder="Repetir contraseña"
                        type="password"
                        //value={userData.name}
                        onChange={
                          (e) => {}
                          //setUserData({ ...userData, name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Solicitar cambio de contraseña"
                  name={"changepass"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SelectProvinceForm
              atributoData={province}
              setAtributoData={setProvince}
            ></SelectProvinceForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectCantonForm
              atributoData={canton}
              provinceData={province}
              setAtributoData={setCanton}
            ></SelectCantonForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SelectCityForm
              atributoData={city}
              provinceData={province}
              setAtributoData={setCity}
            ></SelectCityForm>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SelectGenreForm
              atributoData={genre}
              setAtributoData={setGenre}
            ></SelectGenreForm>
          </Col>
        </Row> */}
            {/* <PageHeader
          className="site-page-header header-left"
          // onBack={() => null}
          style={{ textAlign: "left", justifyContent: "start" }}
          // onBack={() => null}
          title={
            <>
              <PushpinOutlined style={{ fontSize: "30px" }} />{" "}
              <span> Ubicación</span>
            </>
          }
          // subTitle="This is a subtitle"
        /> */}
            {/* <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card
              title={
                <span>
                  Por favor, en el mapa presione en la ubicación de contacto
                  para mover el puntero
                </span>
              }
              extra={
                <Button
                  type="dashed"
                  onClick={() => currentLocation()}
                  icon={<AimOutlined />}
                  size="small"
                  className="mini"
                  loading={searchlocation}
                >
                  buscar mi ubicación
                </Button>
              }
              bodyStyle={{ padding: "0" }}
              //style={{ height: "300px", width: "100%" }}
            >
              
              <div style={{ height: "300px", width: "100%" }}>
                <GoogleMapReact
                  defaultZoom={zoom}
                  defaultCenter={defaultLocation}
                  bootstrapURLKeys={{
                    key: apiKeyGoogle,
                    language: "es",
                    region: "ec",
                    libraries: [],
                  }}
                  onClick={handleChangeLocation}
                  center={location}
                >
                  {!loadingData ? (
                    <Image
                      width={48}
                      src={Pin}
                      lat={location.lat}
                      lng={location.lng}
                      preview={false}
                      style={{ marginTop: "-48px", marginLeft: "-24px" }}
                    />
                  ) : (
                   
                    <Spin width={100} lat={location.lat} lng={location.lng} />
                  )}
                </GoogleMapReact>
              </div>
            </Card>
          </Col>
        </Row> */}
            <br></br>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button
                    className="btnBig"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loadings}
                  >
                    Guardar <SaveOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);
  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpg, image/jpeg,image/png",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={noAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : noAvatar} />
      )}
    </div>
  );
}
