import React from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import "./LayoutBasic.scss";

import AdminSignIn from "../pages/Admin/SignIn";
import AdminCondiciones from "../pages/Admin/Condiciones";

// import Contact from "../pages/Contact";

//Other
import Error404 from "../pages/Error404";

export default function LayoutBasic(props) {
  //const { routes } = props;
  const { Content, Footer } = Layout;

  return (
    <Layout className="layoutbasic">
      <Layout>
        <Content>
          {/* <LoadRoutes routes={routes} /> */}

          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route path="/" element={<AdminSignIn />} />
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/condiciones" element={<AdminCondiciones />}></Route>
          </Routes>
        </Content>
        <Footer className="layoutbasic__footer">OTR - 2024 COPYRIGHT©</Footer>
      </Layout>
    </Layout>
  );
}
