import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Tooltip } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import NotificationOutlined from "@ant-design/icons/NotificationOutlined";
import AlertOutlined from "@ant-design/icons/AlertOutlined";
import BarsOutlined from "@ant-design/icons/BarsOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import AreaChartOutlined from "@ant-design/icons/AreaChartOutlined";
import OrderedListOutlined from "@ant-design/icons/OrderedListOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
import CaretRightOutlined from "@ant-design/icons/CaretRightOutlined";

//import FileOutlined from "@ant-design/icons/FileOutlined";

// import BankOutlined from "@ant-design/icons/BankOutlined";
// import BarsOutlined from "@ant-design/icons/BarsOutlined";

import "./MenuSider.scss";

function MenuSiderUser(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/admin", <HomeOutlined />),
    // getItem("Usuarios", "/admin/user/registros", <UserOutlined />, [
    //   getItem("Listado", "/admin/users", <ArrowRightOutlined />),
    // ]),
    // getItem("Artículos", "/admin/articulos", <NotificationOutlined />, [
    //   getItem("Administrar", "/admin/articulos/listado", <ArrowRightOutlined />),
    //   // getItem(
    //   //   "Emprendimientos",
    //   //   "/admin/mapemprendimiento",
    //   //   <ArrowRightOutlined />
    //   // ),
    // ]),

    getItem("Artículos", "/admin/articulos", <FileTextOutlined />, [
      getItem("Listado", "/admin/articulos/listado", <OrderedListOutlined />),
      getItem(
        "Notificaciones",
        "/admin/articulos/notificacion",
        <NotificationOutlined />
      ),
    ]),

    getItem(
      "Notificaciones exclusivas",
      "/admin/articulos/exclusivas",
      <AlertOutlined />
    ),

    getItem("Administración", "/admin/configuracion", <SettingOutlined />, [
      getItem("Usuarios", "/admin/users", <UserOutlined />),
      getItem("Categorías", "/admin/categorias", <BarsOutlined />),
      getItem("Archivos", "/admin/archivos", <FileOutlined />),
    ]),

    getItem("Reportes", "/admin/reportes", <AreaChartOutlined />, [
      getItem("Lectura artículos", "/admin/lectura", <CaretRightOutlined />),
      getItem("Sin lectura", "/admin/nolectura", <CaretRightOutlined />),
    ]),
    // getItem("Diagnóstico", "/admin/diagnosticos", <DashboardOutlined />, [
    //   getItem("General", "/admin/diagnostico", <ArrowRightOutlined />),
    //   getItem(
    //     "Habilidades blandas",
    //     "/admin/diagnosticoblandas",
    //     <ArrowRightOutlined />
    //   ),
    //   getItem(
    //     "Etapas del emprendimiento",
    //     "/admin/diagnosticoetapas",
    //     <ArrowRightOutlined />
    //   ),
    //   getItem("Facturación", "/admin/facturacion", <ArrowRightOutlined />),
    //   getItem("Empleo", "/admin/empleo", <ArrowRightOutlined />),
    //   getItem("Inversión", "/admin/inversion", <ArrowRightOutlined />),
    // ]),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
    // if (e.key === "/admin/mapusuario" || e.key === "/admin/mapemprendimiento") {
    //   setGroup("/admin/mapas");
    //   navigate(0);
    // }
  };
  //console.log(location);
  return (
    <Sider width={256} theme="dark" collapsed={menuCollapsed}>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 65,
        }}
        //inlineCollapsed={false}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
        theme="dark"
      />
    </Sider>
  );
}

export default MenuSiderUser;
