import React, { useState, useEffect } from "react";
import {
  Switch,
  List,
  Avatar,
  Button,
  notification,
  Empty,
  Row,
  Col,
  Form,
  Input,
  Descriptions,
  Tag,
} from "antd";
import noAvatar from "../../../../assets/img/png/no-avatar.png";
import ModalUser from "../../ModalUser";
import EditUserForm from "../EditUserForm";
import EditPasswordForm from "../EditPasswordForm";

import MapUserForm from "../MapUserForm";

import EditOutlined from "@ant-design/icons/EditOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import StopOutlined from "@ant-design/icons/StopOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";

import { JsonToExcel } from "react-json-to-excel";

import ExportOutlined from "@ant-design/icons/ExportOutlined";

import { getAvatarApi, activateUserApi } from "../../../../api/user.api";

import { getAccessTokenApi } from "../../../../api/auth";

import "./ListUsers.scss";
import SelectRoleOneForm from "../../Role/SelectRoleForm/SelectRoleOneForm";

export default function ListUsers(props) {
  const { usersActive, usersInactive, setReloadUsers, role, setRole, loading } =
    props;
  const [viewUsersActive, setViewUsersActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [usersToExport, setUsersToExport] = useState([]);
  const [q, setQ] = useState("");

  useEffect(() => {
    const data2 = [];
    //console.log(response.haves);
    let users = usersActive;
    users.map((user) =>
      data2.push({
        Perfil: user.rolename,
        Nombres: user.name,
        Apellidos: user.lastname,
        Email: user.email,
        CI: user.ci,
        Teléfono: user.phone,
        Premium: user.premium,
      })
    );
    setUsersToExport(data2);
  }, [usersActive]);

  const addUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle("Agregar usuario");
    setmodalContent(
      <EditUserForm
        user={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
        addSlag="add"
      />
    );
  };

  return (
    <div className="list-users">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={11}>
          <div className="list-users__switch">
            <Switch
              defaultChecked
              onChange={(e) => setViewUsersActive(!viewUsersActive)}
            />
            <span>
              {viewUsersActive ? "Registros activos" : "Registros inactivos"}
            </span>
          </div>
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <Button type="default" title="Nuevo emprendedor" onClick={addUser}>
            Agregar <UserAddOutlined />
          </Button>
        </Col>
        <Col xs={6} sm={6} md={3} lg={3} xl={3}>
          <JsonToExcel
            title={
              <>
                Exportar <ExportOutlined />
              </>
            }
            data={usersToExport}
            fileName="usuarios-activos"
            btnClassName="ant-btn ant-btn-default download"
            btnColor="#f44336"
          />
        </Col>
        <Col xs={18} sm={18} md={6} lg={6} xl={7}>
          <Form.Item>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Buscar"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form
        name="filters"
        initialValues={{ remember: false }}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectRoleOneForm
              atributoData={role}
              setAtributoData={setRole}
            ></SelectRoleOneForm>
          </Col>
        </Row>
      </Form>
      {viewUsersActive ? (
        <UsersActive
          usersActive={usersActive.filter(
            (user) =>
              (user.name + " " + user.lastname)
                .toLowerCase()
                .includes(q.toLowerCase()) ||
              user.email.toLowerCase().includes(q.toLowerCase()) ||
              user.ci.toLowerCase().includes(q.toLowerCase())
          )}
          //usersActive={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setmodalContent={setmodalContent}
          setReloadUsers={setReloadUsers}
          loading={loading}
        />
      ) : (
        <UsersInactive
          usersInactive={usersInactive.filter(
            (user) =>
              (user.name + " " + user.lastname)
                .toLowerCase()
                .includes(q.toLowerCase()) ||
              user.email.toLowerCase().includes(q.toLowerCase()) ||
              user.ci.toLowerCase().includes(q.toLowerCase())
          )}
          setReloadUsers={setReloadUsers}
        />
      )}
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={1000}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setModalTitle,
    setIsVisibleModal,
    setmodalContent,
    setReloadUsers,
    loading,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        user.name + " " + user.lastname ? user.name + " " + user.lastname : "-"
      }`
    );
    setmodalContent(
      <EditUserForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
        addSlag="edit"
      />
    );
  };

  const editPassUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Cambiar contraseña ${
        user.name + " " + user.lastname ? user.name + " " + user.lastname : "-"
      }`
    );
    setmodalContent(
      <EditPasswordForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
        addSlag="edit"
      />
    );
  };

  const mapUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Ubicación ${
        user.name + " " + user.lastname ? user.name + " " + user.lastname : "-"
      }`
    );
    setmodalContent(
      <MapUserForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
        addSlag="edit"
      />
    );
  };

  return (
    <List
      className="users-active"
      loading={loading}
      itemLayout="vertical"
      size="small"
      dataSource={usersActive}
      pagination={{
        // onChange: page => {
        //   console.log(page);
        // },
        pageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} registros`,
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen usuarios activos"
          />
        ),
      }}
      renderItem={(user) => (
        <UserActive
          user={user}
          editUser={editUser}
          editPassUser={editPassUser}
          mapUser={mapUser}
          setReloadUsers={setReloadUsers}
        />
      )}
    />
  );
}

function UserActive(props) {
  const { user, editUser, editPassUser, mapUser, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (user.photo) {
      getAvatarApi(user.photo).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const desactivateUser = () => {
    const accessToken = getAccessTokenApi();
    activateUserApi(accessToken, user.id, false)
      .then((response) => {
        notification["success"]({
          message: "Registro desactivado",
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <List.Item
      style={{ marginBottom: "25px" }}
      actions={[
        // <Button type="principal" onClick={() => mapUser(user)}>
        //   <AimOutlined />
        // </Button>,
        <Button type="dashed" onClick={() => editUser(user)}>
          <EditOutlined />
        </Button>,
        <Button type="dashed" onClick={() => editPassUser(user)}>
          <LockOutlined />
        </Button>,
        // <Button type="danger" onClick={() => console.log("Borrar")}>
        //   <DeleteOutlined />
        // </Button>,
        <Button
          type="danger"
          title="Desactivar registro"
          onClick={desactivateUser}
        >
          <StopOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : noAvatar} />}
        title={
          <span>
            {user.name} {user.lastname}
          </span>
        }
        // {
        //   <b>{user.lastname}</b> +
        //   "," +
        //   <span>${user.name ? user.name : "-"}</span>
        // }
        description={"CI: " + user.ci}
      />
      <Descriptions
        //title="Responsive Descriptions"
        bordered
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 2,
          sm: 1,
          xs: 1,
        }}
        size="small"
      >
        <Descriptions.Item label="Perfil">{user.rolename}</Descriptions.Item>
        <Descriptions.Item label="Mail">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Teléfono">{user.phone}</Descriptions.Item>
        <Descriptions.Item label="Cuenta">
          {user.premium ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Premium
            </Tag>
          ) : (
            <Tag color="default">Estándar</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </List.Item>
  );
}

function UsersInactive(props) {
  const { usersInactive, setReloadUsers } = props;
  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      size="small"
      dataSource={usersInactive}
      pagination={{
        // onChange: page => {
        //   console.log(page);
        // },
        pageSize: 10,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} registros`,
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen registros inactivos"
          />
        ),
      }}
      renderItem={(user) => (
        <UserInactive user={user} setReloadUsers={setReloadUsers} />
      )}
    />
  );
}

function UserInactive(props) {
  const { user, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (user.photo) {
      getAvatarApi(user.photo).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const activateUser = () => {
    const accessToken = getAccessTokenApi();
    activateUserApi(accessToken, user.id, true)
      .then((response) => {
        //console.log(response);
        notification["success"]({
          message: "Registro activado",
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <List.Item
      actions={[
        // <Button type="danger" onClick={() => console.log("Borrar")}>
        //   <DeleteOutlined />
        // </Button>,
        <Button type="primary" title="Activar registro" onClick={activateUser}>
          <CheckOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : noAvatar} />}
        title={
          <span>
            {user.name} {user.lastname}
          </span>
        }
        description={user.email}
      />
    </List.Item>
  );
}
