import React, { useState, useEffect } from "react";
import { Select, Form, Input } from "antd";

import { getTypesApi } from "../../../../api/type.api";

import "./SelectTypeForm.scss";
import { getAccessTokenApi } from "../../../../api/auth";

const { Option } = Select;

export default function SelectTypeForm(props) {
  const { atributoData, setAtributoData, deafultValue } = props;
  const [typeData, setTypeData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [typeId, setTypeId] = useState();

  useEffect(() => {
    getTypesApi(token).then((response) => {
      setTypeData(response.data);
      // console.log(atributoData);
      // setTypeId(atributoData);
    });
    // if (atributoData) {
    //   setTypeId(atributoData);
    // }
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setTypeId(value);
    setAtributoData(value);
  };

  return (
    <div className="select-type-form">
      <Form.Item
        label="Categoría"
        name="typeId"
        rules={[{ required: true, message: "Seleccione una categoría" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //style={other && { marginBottom: "5px" }}
        initialValue={atributoData}
        //initialValue={typeInitial}
      >
        <Select
          placeholder="Seleccione una categoría"
          value={typeId}
          onChange={handleAtributoChange}
          //mode="multiple"
          //defaultValue={atributoData}
          //disabled={disabled}
        >
          {typeData && typeData.map
            ? typeData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
