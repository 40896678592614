import { appIdOnesignal, keyOnesignal } from "./config";

export async function notificationApi(users, title, type, id) {
  const url = `https://onesignal.com/api/v1/notifications`;

  const params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Basic ${keyOnesignal}`,
    },
    body: JSON.stringify({
      include_aliases: { external_id: users },
      target_channel: "push",
      contents: { en: title },
      name: "Notificaciones",
      app_id: appIdOnesignal,
      ios_badgeType: "Increase",
      ios_badgeCount: 1,
      data: {
        type: type,
        id: id,
      },
    }),
  };

  console.log(
    JSON.stringify({
      include_aliases: { external_id: users },
      target_channel: "push",
      contents: { en: title },
      name: "Notificaciones",
      app_id: appIdOnesignal,
      data: {
        type: type,
        id: id,
      },
    })
  );

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
