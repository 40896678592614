import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import routes from "./config/routes";
import AuthProvider from "./providers/authProvider";
import socketClient from "socket.io-client";
import { basePath } from "./api/config";
//Layout
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBasic from "./layouts/LayoutBasic";
import LayoutEscritor from "./layouts/LayoutWriter";

import AdminHome from "./pages/Admin";
import AdminSignIn from "./pages/Admin/SignIn";
import AdminCondiciones from "./pages/Admin/Condiciones";
import AdminUsers from "./pages/Admin/Users";
import AdminArticles from "./pages/Admin/Articulos";
import AdminNotification from "./pages/Admin/Notificacion";
import AdminNotificationExclusive from "./pages/Admin/Exclusivos";
import AdminTypes from "./pages/Admin/Type";
import AdminUploads from "./pages/Admin/Upload";
import AdminLectura from "./pages/Admin/Reports/Lectura";
import AdminNoLectura from "./pages/Admin/Reports/Nolectura";

import WriterHome from "./pages/Writer";
import WriterArticles from "./pages/Writer/Articulos";
import WriterNotification from "./pages/Writer/Notificacion";
import WriterFiles from "./pages/Writer/Upload";
import WriterExclusives from "./pages/Writer/Exclusivos";

//Other
import Error404 from "./pages/Error404";

import "./App.scss";

function App() {
  var socket = socketClient(basePath, {
    transports: ["websocket", "polling", "flashsocket"],
  });

  //var socket = io('http://yourDomain:port', { transports: ['websocket', 'polling', 'flashsocket'] });

  socket.on("connection", () => {
    console.log(`I'm connected with the back-end`);
  });
  return (
    <AuthProvider>
      {/* <Router>
        <Routes>
          {routes.map((route, index) => (
            <RouterWithSubRoutes key={index} {...route} />
          ))}
        </Routes>
      </Router> */}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error404 />} />
          <Route element={<LayoutBasic />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="/*" element={<Error404 />} /> */}
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/condiciones" element={<AdminCondiciones />} />
            <Route path="/" element={<AdminSignIn />} />
          </Route>
          <Route element={<LayoutAdmin />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route
              path="/admin/articulos/listado"
              element={<AdminArticles />}
            />
            <Route
              path="/admin/articulos/notificacion"
              element={<AdminNotification />}
            />
            <Route
              path="/admin/articulos/exclusivas"
              element={<AdminNotificationExclusive />}
            />
            <Route path="/admin/categorias" element={<AdminTypes />} />
            <Route path="/admin/archivos" element={<AdminUploads />} />
            <Route path="/admin/lectura" element={<AdminLectura />} />
            <Route path="/admin/nolectura" element={<AdminNoLectura />} />
          </Route>
          <Route element={<LayoutEscritor />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/escritor" element={<WriterHome />} />
            <Route
              path="/escritor/articulos/listado"
              element={<WriterArticles />}
            />
            <Route
              path="/escritor/articulos/notificacion"
              element={<WriterNotification />}
            />
            <Route
              path="/escritor/articulos/archivos"
              element={<WriterFiles />}
            />
            <Route
              path="/escritor/articulos/exclusivos"
              element={<WriterExclusives />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

// function RouterWithSubRoutes(route) {
//   return (
//     <Route
//       path={route.path}
//       //exact={route.exact}
//       render={(props) => <route.component routes={route.routes} {...props} />}
//     />
//   );
// }

export default App;
