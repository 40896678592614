import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getArticlesSelectApi } from "../../../../api/article.api";

import "./SelectArticleForm.scss";

const { Option } = Select;

export default function SelectArticleAllForm(props) {
  const { atributoData, setAtributoData } = props;
  const [articleData, setArticleData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [articleId, setArticleId] = useState();

  useEffect(() => {
    getArticlesSelectApi(token).then((response) => {
      setArticleData(response.data);
      setArticleId(atributoData);
      //   if (addSlag === "add") {
      //     setArticleId(null);
      //   } else {
      //     setArticleId(atributoData.articleId);
      //   }
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setArticleId(value);
    setAtributoData(value ?? 0);
  };

  return (
    <div className="select-article-form">
      <Form.Item
        label="Artículo"
        name="articleId"
        //rules={[{ required: true, message: "Seleccione un artículo" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //value={provinceId}
      >
        <Select
          placeholder="Seleccione un artículo"
          value={articleId}
          onChange={handleAtributoChange}
          showSearch
          optionFilterProp="children"
          loading={loadingData}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          allowClear={true}
          //disabled={disabled}
        >
          {articleData && articleData.map
            ? articleData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
