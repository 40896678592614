import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getLectorsApi } from "../../../../api/user.api";

import "./SelectUserForm.scss";

const { Option } = Select;

export default function SelectUserForm(props) {
  const { atributoData, setAtributoData } = props;
  const [userData, setUserData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [userId, setUserId] = useState();

  useEffect(() => {
    getLectorsApi(token, "0").then((response) => {
      setUserData(response.users);
      setUserId(atributoData);
      //   if (addSlag === "add") {
      //     setArticleId(null);
      //   } else {
      //     setArticleId(atributoData.articleId);
      //   }
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value) => {
    setUserId(value);
    setAtributoData(value ?? 0);
  };

  return (
    <div className="select-article-form">
      <Form.Item
        label="Lectores"
        name="userId"
        //rules={[{ required: true, message: "Seleccione un lector" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //value={provinceId}
      >
        <Select
          placeholder="Seleccione un lector"
          value={userId}
          onChange={handleAtributoChange}
          showSearch
          optionFilterProp="children"
          loading={loadingData}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          allowClear={true}
          //disabled={disabled}
        >
          {userData && userData.map
            ? userData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name} {item.lastname} (
                  {item.premium ? "Premium" : "Estándar"})
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
