import { basePath } from "./config";

export async function getUploadsApi(token) {
  const url = `${basePath}/uploads`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}

export function UpdateUploadApi(token, upload, uploadId) {
  const url = `${basePath}/upload/update/${uploadId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(upload),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddUploadApi(token, upload) {
  const url = `${basePath}/upload/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(upload),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteUploadApi(token, uploadId) {
  const url = `${basePath}/upload/delete/${uploadId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getMaxUploadApi(token) {
  const url = `${basePath}/upload/max`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getUploadsGroupApi(token, province) {
  const url = `${basePath}/upload/group?province=${province}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function uploadVideoApi(token, file) {
  const url = `${basePath}/upload-video`;

  const formData = new FormData();

  console.log(file);

  //for (const name in file) {
  formData.append("name", file["name"]);
  formData.append("file", file);
  //}

  const params = {
    method: "POST",
    headers: {
      //"Content-Type": "application/json",
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      //"Content-Type": "multipart/form-data",
      Authorization: token,
    },
    body: formData,
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getUserUploadsApi(token, user) {
  const url = `${basePath}/uploads-user/${user}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  try {
    const response = await fetch(url, params);
    const result_1 = await response.json();
    return result_1;
  } catch (err) {
    return err.message;
  }
}
