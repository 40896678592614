import React, { useState } from "react";
import { Navigate, Routes, Route, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSider from "../components/Admin/MenuSider";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminCondiciones from "../pages/Admin/Condiciones";
import AdminUsers from "../pages/Admin/Users";
import AdminArticles from "../pages/Admin/Articulos";
import AdminNotification from "../pages/Admin/Notificacion";
import AdminNotificationExclusive from "../pages/Admin/Exclusivos";
import AdminTypes from "../pages/Admin/Type";
import AdminUploads from "../pages/Admin/Upload";
import AdminLectura from "../pages/Admin/Reports/Lectura";
import AdminNoLectura from "../pages/Admin/Reports/Nolectura";

import "./LayoutAdmin.scss";
import useAuth from "../hooks/useAuth";

export default function LayoutAdmin(props) {
  //const { routes } = props;
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;

  const { user, isLoading } = useAuth(); //hook
  const navigate = useNavigate();

  console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          <Route path="/condiciones" element={<AdminCondiciones />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 1) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    //navigate("/user");
    return (
      <Layout>
        <MenuSider menuCollapsed={menuCollapsed} />
        <Layout
          className="layout-admin"
          // style={{ marginLeft: menuCollapsed ? "80px" : "200px" }}
        >
          <Header className="layout-admin__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-admin__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/admin" element={<AdminHome />} />
              {/* <Route path="/admin/login" element={<AdminSignIn />} /> */}
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route
                path="/admin/articulos/listado"
                element={<AdminArticles />}
              />
              <Route
                path="/admin/articulos/notificacion"
                element={<AdminNotification />}
              />
              <Route
                path="/admin/articulos/exclusivas"
                element={<AdminNotificationExclusive />}
              />
              <Route path="/admin/categorias" element={<AdminTypes />} />
              <Route path="/admin/archivos" element={<AdminUploads />} />
              <Route path="/admin/lectura" element={<AdminLectura />} />
              <Route path="/admin/nolectura" element={<AdminNoLectura />} />
            </Routes>
          </Content>
          <Footer className="layout-admin__footer">
            OTR - 2024 COPYRIGHT©
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes({ routes }) {
//   return (
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           exact={route.exact}
//           //component={route.component}
//           element={<route.component />}
//         />
//       ))}
//     </Routes>
//   );
// }
