import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
// import { useNavigate } from "react-router-dom";

import { Table, Button, Empty, Breadcrumb, Affix, Tag, Image } from "antd";

import { UserOutlined } from "@ant-design/icons/";

import "./Notificacion.scss";
import { getNotificationsUserApi } from "../../../api/notification.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditNotificationForm from "../../../components/Admin/Notification/EditNotificationForm";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";

export default function Notification() {
  const [notification, setNotification] = useState([]);
  const token = getAccessTokenApi();
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const { user, isLoading } = useAuth(); //hoo
  // const navigate = useNavigate();
  // const url = window.location.origin;

  useEffect(() => {
    getNotificationsUserApi(token, user.id).then((response) => {
      setNotification(response.data);
      setLoadingData(false);
    });

    setReloadNotifications(false);
  }, [token, reloadNotifications]);

  const addNotification = () => {
    setIsVisibleModal(true);
    setModalTitle("Enviar notificación");
    setmodalContent(
      <EditNotificationForm
        notification={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNotifications={setReloadNotifications}
        buttonName="Enviar notificación"
        addSlag={"add"}
      />
    );
  };

  const editNotification = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar notificación");
    setmodalContent(
      <EditNotificationForm
        notification={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNotifications={setReloadNotifications}
        buttonName="Editar notificación"
        addSlag={"edit"}
      />
    );
  };

  const deleteNotification = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar notificación");
    setmodalContent(
      <EditNotificationForm
        notification={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNotifications={setReloadNotifications}
        buttonName="Eliminar notificación"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Mensaje",
      width: 150,
      dataIndex: "message",
      key: "message",
      fixed: "left",
    },

    {
      title: "Fecha de notificación",
      width: 100,
      dataIndex: "date",
      key: "date",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    },
    {
      title: "Artículo",
      width: 100,
      dataIndex: ["article", "title"],
      key: "articleId",
    },
    {
      title: "Autor",
      width: 100,
      //dataIndex: ["user", "name"],
      key: "autor",
      render: (text, record) => (
        <span>
          {record.user ? record.user.name : ""}{" "}
          {record.user ? record.user.lastname : ""}
        </span>
      ),
    },
    // {
    //   title: "Acciones",
    //   key: "operation",
    //   fixed: "right",
    //   width: 120,
    //   render: (text, record) => (
    //     <div>
    //       <Button
    //         size="small"
    //         type="default"
    //         className="margin-right-minimun"
    //         onClick={() => editNotification(record)}
    //       >
    //         <UserOutlined />
    //       </Button>
    //       {/* <Button
    //         size="small"
    //         type="default"
    //         onClick={() => navigate(`/admin/notifications/details/${record.id}`)}
    //       >
    //         <FolderOpenOutlined />
    //       </Button> */}
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className="notification">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Escritor</Breadcrumb.Item>
        <Breadcrumb.Item>Notificaciones</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addNotification}>
          Enviar notificación
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <p>Destinatarios:</p>
              <p style={{ margin: 0 }}>
                {record.users && record.users.map
                  ? record.users.map((item) => (
                      <Tag color="green" key={item.id}>
                        {item.name} {item.lastname}
                      </Tag>
                    ))
                  : ""}
              </p>
            </>
          ),
          rowExpandable: (record) => record.users.length > 0,
        }}
        dataSource={notification}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen notificaciones"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={1200}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
