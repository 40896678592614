import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  // Upload,
  // message,
  // Tag,
  Checkbox,
  Switch,
  Divider,
  DatePicker,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateExclusiveApi,
  AddExclusiveApi,
  DeleteExclusiveApi,
} from "../../../../api/exclusive.api";

import { notificationApi } from "../../../../api/onesignal.api";

import { getLectorsApi } from "../../../../api/user.api";

//import useAuth from "../../../../../hooks/useAuth";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "./EditExclusiveForm.scss";
//import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import moment from "moment";
import SelectTypeForm from "../../../../components/Admin/Type/SelectTypeForm";

import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";
import "./quill.snow.css";
import useAuth from "../../../../hooks/useAuth";

import { PropTypes } from "prop-types";

import ModalUser from "../../ModalUser/ModalArticleUser";
import EditUploadForm from "../../Upload/EditUploadForm/EditUploadArticleForm";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/*
 * Simple editor component that takes placeholder text as a prop
 */

const Quill = ReactQuill.Quill;
const BlockEmbed = Quill.import("blots/block/embed");
const Font = Quill.import("formats/font");
Font.whitelist = [
  // "sans serif",
  // "serif",
  // "monospace",
  "Gothambook",
  "Gothamultra",
  "Gothamlight",
  "Gothammedium",
  "Ibarra",
  "Ibarrabold",
];
Quill.register(Font, true);
//const Inline = Quill.import("blots/inline");
class AudioBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
}
AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";
Quill.register(AudioBlot);

class VideoBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
}
VideoBlot.blotName = "videoupload";
VideoBlot.tagName = "video";
Quill.register(VideoBlot);

class LinkBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.innerText = url.value;
    node.setAttribute("href", url.url);
    node.setAttribute("target", "_blank");
    return node;
  }
  // static formats(node) {
  //   return node.getAttribute("href");
  // }
  static value(node) {
    return node.getAttribute("href");
  }
}
LinkBlot.blotName = "linkupload";
LinkBlot.tagName = "a";
Quill.register(LinkBlot);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: "",
      mountedEditor: false,
      isVisibleUploadModal: false,
      modalTitle: "Agregar nuevo archivo",
      modalContent: null,
      uploadUrl: "",
    };
    this.quillRef = null;
    this.reactQuillRef = null;
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickVideo = this.handleClickVideo.bind(this);
    this.handleClickPdf = this.handleClickPdf.bind(this);
    this.attachQuillRefs = this.attachQuillRefs.bind(this);
    this.update = this.update.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.updateUrlVideo = this.updateUrlVideo.bind(this);
    this.updateUrlPdf = this.updateUrlPdf.bind(this);
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs() {
    // Ensure React-Quill reference is available:
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;

    const quillRef = this.reactQuillRef.getEditor();
    if (quillRef != null) this.quillRef = quillRef;
  }

  update(nextState) {
    //console.log(nextState);
    this.setState(nextState);
  }

  updateUrl(url, range) {
    this.attachQuillRefs();
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //console.log(this.quillRef);
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "audio", url, "user");
  }

  handleClick(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();

    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrl}
          buttonName="Agregar archivo de audio"
          addSlag={"add"}
          uploadType={"audio/mp3,audio/*;"}
          range={range}
        />
      ),
    });
  }

  updateUrlVideo(url, range) {
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "videoupload", url, "user");
  }

  handleClickVideo(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();
    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrlVideo}
          buttonName="Agregar archivo de video"
          addSlag={"add"}
          uploadType={"video/*"}
          range={range}
        />
      ),
    });
  }

  updateUrlPdf(url, range) {
    this.setState({ uploadUrl: url });
    //var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    //this.quillRef.insertText(position, 'Hello, World! ')
    this.quillRef.insertEmbed(position, "linkupload", url, "user");
  }

  handleClickPdf(e) {
    e.preventDefault();
    var range = this.quillRef.getSelection();
    this.setState({ isVisibleUploadModal: true });
    this.setState({
      modalContent: (
        <EditUploadForm
          upload={null}
          setIsVisibleModal={this.update}
          setReloadUploads={this.update}
          setUrl={this.updateUrlPdf}
          buttonName="Agregar documento"
          addSlag={"add"}
          uploadType={"application/pdf"}
          range={range}
        />
      ),
    });
  }

  handleChange(html) {
    this.props.setEditor({ editorHtml: html });
    this.setState({ editorHtml: html });
  }

  render() {
    return (
      <div>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme={"snow"}
          onChange={this.handleChange}
          modules={Editor.modules}
          formats={Editor.formats}
          value={this.props.editor.editorHtml}
          //defaultValue={this.state.editorHtml}
          //value={this.state.editorHtml}
          placeholder={"Aquí el contenido del artículo..."}
        />
        <button type="primary" onClick={this.handleClick}>
          Insertar audio
        </button>
        <button type="primary" onClick={this.handleClickVideo}>
          Insertar video
        </button>
        <button type="primary" onClick={this.handleClickPdf}>
          Insertar documento
        </button>
        <ModalUser
          title={this.state.modalTitle}
          isVisibleModal={this.state.isVisibleUploadModal}
          setIsVisibleModal={this.update}
        >
          {this.state.modalContent}
        </ModalUser>
      </div>
    );
  }
}

Editor.modules = {};
Editor.modules.toolbar = [
  // ["bold", "italic", "underline", "strike"], // toggled buttons
  // ["blockquote", "code-block"], // blocks
  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: "ordered" }, { list: "bullet" }], // lists
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction
  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  // [{ color: [] }, { background: [] }], // dropdown with defaults
  // [{ font: [] }], // font family
  // [{ align: [] }], // text align
  // ["clean"], // remove formatting
  [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
  [{ size: [] }],
  // ["bold", "italic", "underline", "strike", "blockquote"],
  ["bold", "italic", "underline", "strike"],
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
];

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "align",
  "audio",
  "videoupload",
  "linkupload",
];

Editor.propTypes = {
  placeholder: PropTypes.string,
  editor: PropTypes.object,
  setEditor: function (props, propname, componentname) {
    //const prop = props[propname];
    // console.log(props.editor);
    // console.log(propname);
    // console.log(componentname);
    this.setState(props.editor);
    this.attachQuillRefs();
  },
  // title: PropTypes.string,
  // isVisibleModal: PropTypes.bool,
  // setIsVisibleModal: function (props, propname, componentname) {
  //   //const prop = props[propname];
  // },
  // modalContent: PropTypes.element,
};

export default function EditExclusiveForm(props) {
  const {
    exclusive,
    setIsVisibleModal,
    setReloadExclusives,
    buttonName,
    addSlag,
    date,
  } = props;
  const { user, isLoading } = useAuth();

  const [exclusiveData, setExclusiveData] = useState({});
  const token = getAccessTokenApi();
  const [formExclusive] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  //const urlclient = window.location.origin;
  const [type, setType] = useState();
  const [userData, setUserData] = useState({});
  const [userDataAll, setUserDataAll] = useState([]);

  const [exclusiveusers, setExclusiveusers] = useState([]);
  //const [notificationusers, setNotificationusers] = useState([]);

  // const [tags, setTags] = useState(["Unremovable", "Tag 2", "Tag 3"]);
  // const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // const [editInputIndex, setEditInputIndex] = useState(-1);
  // const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  //const [checkedList, setCheckedList] = useState(exclusiveusers);
  const [premium, setPremium] = useState("0");
  const [editor, setEditor] = useState({ editorHtml: "", theme: "snow" });

  useEffect(() => {
    if (exclusive) {
      if (exclusive.date && exclusive.date !== undefined) {
        exclusive.date = moment(exclusive.date);
      }
      //console.log(exclusive);
      setEditor({ editorHtml: exclusive.description });
      const data = [];
      // let users = exclusive.users;
      // console.log(users);
      // // users.map((user) => data.push(user.id));
      // users.map((user) => data.push(user.id));
      // exclusive.users = data;
      formExclusive.setFieldsValue(exclusive);
      // console.log(data);
      // setExclusiveusers(data);
    } else {
      formExclusive.setFieldsValue({
        title: null,
        summary: null,
        status: 1,
        date: moment().add(1, "M"),
        typeId: null,
        userId: null,
        users: [],
      });
    }

    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [exclusive]);

  useEffect(() => {
    // getLectorsApi(token, premium).then((response) => {
    //   const data = [];
    //   const data1 = [];
    //   let users = response.users;
    //   users.map((user) =>
    //     data.push({
    //       label: `${user.name} ${user.lastname} (${
    //         user.premium ? "Premium" : "Estándar"
    //       })`,
    //       value: user.id,
    //       email: user.email,
    //     })
    //   );
    //   users.map((user) => data1.push(user.id));
    //   console.log(data);
    //   setUserData(data);
    //   setUserDataAll(data1);
    //   setLoading(false);
    // });

    if (addSlag === "add") {
      //setImageUrl(null);
      //setTags([]);
      setEditor({ editorHtml: "" });
      formExclusive.setFieldsValue({
        title: null,
        summary: null,
        status: 1,
        date: moment().add(1, "M"),
        typeId: null,
        userId: null,
        users: [],
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, []);

  useEffect(() => {
    // getLectorsApi(token, premium).then((response) => {
    //   const data = [];
    //   const data1 = [];
    //   let users = response.users;
    //   users.map((user) =>
    //     data.push({
    //       label: `${user.name} ${user.lastname} (${
    //         user.premium ? "Premium" : "Estándar"
    //       })`,
    //       value: user.id,
    //       email: user.email,
    //     })
    //   );
    //   users.map((user) => data1.push(user.id));
    //   console.log(data);
    //   setUserData(data);
    //   setUserDataAll(data1);
    //   setLoading(false);
    // });

    if (addSlag === "add") {
      //setImageUrl(null);
      //setTags([]);
      setEditor({ editorHtml: "" });
      formExclusive.setFieldsValue({
        title: null,
        summary: null,
        status: 1,
        date: moment().add(1, "M"),
        typeId: null,
        userId: null,
        users: [],
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [date]);

  useEffect(() => {
    getLectorsApi(token, premium).then((response) => {
      const data = [];
      const data1 = [];
      let users = response.users;
      users.map((user) =>
        data.push({
          label: `${user.name} ${user.lastname} (${
            user.premium ? "Premium" : "Estándar"
          })`,
          value: user.id,
          email: user.email,
        })
      );
      users.map((user) => data1.push(user.id));
      console.log(data);
      setUserData(data);
      setUserDataAll(data1);
      setLoading(false);
    });

    if (addSlag === "add") {
      formExclusive.setFieldsValue({
        users: [],
      });
    }
  }, [premium]);

  const onFinish = (values) => {
    setSending(true);
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddExclusiveApi(token, {
        ...values,
        description: editor.editorHtml,
        userId: user.id,
        //description: editor.editorHtml,
      }).then((result) => {
        if (result.status) {
          const data = [];
          let users = values.users;
          console.log(userData);
          users.map((user) =>
            data.push(
              userData.find((obj) => {
                return obj.value === user;
              }).email
            )
          );
          notificationApi(
            data,
            values.title,
            "exclusive",
            result.notification.id
          ).then((result) => {
            console.log(result);
          });
          //console.log(data);

          //return;

          notification["success"]({
            message: result.message,
          });
          setSending(false);
          setReloadExclusives(true);
          setIsVisibleModal(false);
        } else {
          setSending(false);
          notification["error"]({
            message: result.message,
          });
        }

        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateExclusiveApi(
        token,
        {
          ...values,
          description: editor.editorHtml,
          userId: user.id,
        },
        exclusive.id
      ).then((result) => {
        const data = [];
        let users = values.users;
        console.log(userData);
        users.map((user) =>
          data.push(
            userData.find((obj) => {
              return obj.value === user;
            }).email
          )
        );
        notificationApi(data, values.title, "exclusive", exclusive.id).then(
          (result) => {
            console.log(result);
          }
        );

        notification["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadExclusives(true);
        setIsVisibleModal(false);
        setExclusiveData({
          ...exclusiveData,
        });
      });
    } else {
      DeleteExclusiveApi(token, exclusive.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadExclusives(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    console.log(imageUrl);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  // const onChange = (checkedValues) => {
  //   console.log("checked = ", checkedValues);
  // };

  const onCheckAllChange = (e) => {
    console.log(userDataAll);
    //setCheckedList(e.target.checked ? userDataAll : []);
    //setExclusiveusers(e.target.checked ? userDataAll : []);
    if (e.target.checked) {
      formExclusive.setFieldsValue({ users: userDataAll });
    } else {
      formExclusive.setFieldsValue({ users: [] });
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onChange = (list) => {
    console.log(list);
    //setCheckedList(list);
    setIndeterminate(!!list.length && list.length < userDataAll.length);
    setCheckAll(list.length === userDataAll.length);
  };

  const onChangePremium = ({ target: { value } }) => {
    setPremium(value);
    setIndeterminate(false);
    setCheckAll(false);
  };

  return (
    <div className="edit-exclusive-form">
      <Form
        form={formExclusive}
        name="exclusive"
        className="form-edit"
        //onSubmit={addUpdateExclusive}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        //initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={18} lg={20} xl={21}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                <Form.Item
                  label="Título del artículo"
                  name={"title"}
                  rules={[
                    {
                      required: true,
                      message: "Ingrese el título del artículo",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Título del artículo"
                    maxLength={300}
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                <SelectTypeForm
                  atributoData={type}
                  setAtributoData={setType}
                ></SelectTypeForm>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                <Form.Item
                  label="Articulo visible"
                  name={"status"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label="Descripción corta"
              name={"description"}
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción corta",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TextArea
                //prefix={<UserOutlined />}
                rows={2}
                placeholder="Descripción corta"
                maxLength={1200}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={16} lg={18} xl={18}>
            <Form.Item
              label="Resumen"
              name={"summary"}
              rules={[
                {
                  required: true,
                  message: "Ingrese un resumen",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <TextArea
                //prefix={<UserOutlined />}
                rows={2}
                placeholder="Resumen"
                maxLength={1200}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <Form.Item
              label="Fecha de caducidad de la noticia"
              name="date"
              rules={[
                {
                  required: true,
                  message:
                    "Seleccione la fecha hasta cuando caduque la noticia",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm",
                }}
                style={{ width: "100%" }}
                format="YYYY-MM-DD HH:mm"
                defaultValue={moment().add(1, "M")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Artículo"
              name={"description"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Ingrese el artículo",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              {/* <ReactQuill
                // ref={(el) => {
                //   quillRef.current = el;
                // }}
                //forwardedRef={quillRef}
                ref={quillRef}
                theme={editor.theme}
                onChange={handleChangeEditor}
                value={editor.editorHtml}
                modules={modules}
                formats={formats}
                bounds={".app"}
                placeholder={"Aquí el contenido del artículo"}
              /> */}
              <Editor
                placeholder={"Aquí el contenido del artículo..."}
                editor={editor}
                setEditor={setEditor}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <br />
            <div class="ant-col ant-col-24 ant-form-item-label">
              <label
                for="exclusive_users"
                class="ant-form-item-required"
                title="Destinatarios"
              >
                Destinatarios (Se muestra solo premium)
              </label>
            </div>

            <Row gutter={24}>
              
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Seleccionar todos
                </Checkbox>
              </Col>
            </Row>
            <Divider />
            <Form.Item
              // label={
              //   <>
              //     Destinatarios
              //     <br />
              //     <Checkbox
              //       indeterminate={indeterminate}
              //       onChange={onCheckAllChange}
              //       checked={checkAll}
              //     >
              //       Todos
              //     </Checkbox>
              //   </>
              // }
              name={"users"}
              rules={[
                {
                  required: true,
                  message: "Seleccione al menos un destinatario",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              initialValue={exclusiveusers}
              //value={checkedList}
            >
              
              <Checkbox.Group
                //loading={loading}
                options={userData}
                onChange={onChange}
                //value={checkedList}
                //onChange={onChange}
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {/* <Form.Item
              label="Destinatarios"
              name={"users"}
              rules={[
                {
                  required: true,
                  message: "Seleccione al menos un destinatario",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Checkbox.Group
                loading={loading}
                options={userData}
                defaultValue={["Apple"]}
              />
            </Form.Item> */}
            <br />
            <div class="ant-col ant-col-24 ant-form-item-label">
              <label
                for="exclusive_users"
                class="ant-form-item"
                title="Destinatarios"
              >
                Destinatarios (Se muestra solo premium)
              </label>
            </div>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                {/* Mostrar solo premium <Switch onChange={onChangePremium} /> */}
                <Radio.Group
                  options={[
                    {
                      label: "Todos",
                      value: "0",
                    },
                    {
                      label: "Premium",
                      value: "1",
                    },
                    {
                      label: "Estándar",
                      value: "2",
                    },
                  ]}
                  onChange={onChangePremium}
                  value={premium}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Seleccionar todos
                </Checkbox>
              </Col>
            </Row>
            <Divider />
            <Form.Item
              // label={
              //   <>
              //     Destinatarios
              //     <br />
              //     <Checkbox
              //       indeterminate={indeterminate}
              //       onChange={onCheckAllChange}
              //       checked={checkAll}
              //     >
              //       Todos
              //     </Checkbox>
              //   </>
              // }
              name={"users"}
              // rules={[
              //   {
              //     required: true,
              //     message: "Seleccione al menos un destinatario",
              //   },
              // ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              //initialValue={exclusiveusers}
              //value={checkedList}
            >
              {/* <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox> */}
              <Checkbox.Group
                //loading={loading}
                options={userData}
                onChange={onChange}
                //value={checkedList}
                //onChange={onChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
            loading={sending}
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
