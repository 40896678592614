import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
// import { useNavigate } from "react-router-dom";

import { Table, Button, Empty, Breadcrumb, Affix, Tag, Image } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  // FolderOpenOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  NotificationOutlined,
} from "@ant-design/icons/";

import "./Exclusivos.scss";
import { getExclusivesPageApi } from "../../../api/exclusive.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditExclusiveForm from "../../../components/Admin/Exclusive/EditExclusiveForm";
import moment from "moment";
//import EditNotificationExclusiveForm from "../../../components/Admin/Notification/EditNotificationForm/EditNotificationExclusiveForm";

export default function Exclusive() {
  const [exclusive, setExclusive] = useState([]);
  const token = getAccessTokenApi();
  const [reloadExclusives, setReloadExclusives] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageinit, setPageinit] = useState(1);
  const [pagesize, setPagesize] = useState(5);
  // const navigate = useNavigate();
  // const url = window.location.origin;

  useEffect(() => {
    // getExclusivesPageApi(token).then((response) => {
    //   setExclusive(response.exclusives);
    //   //console.log(response.exclusives);
    //   setLoadingData(false);
    // });

    fetchRecords(pageinit, pagesize);

    setReloadExclusives(false);
  }, [token, reloadExclusives]);

  const fetchRecords = (page_, pageSize_) => {
    setPageinit(page_);
    setPagesize(pageSize_);
    setLoadingData(true);
    getExclusivesPageApi(token, pageSize_, page_).then((response) => {
      setExclusive(response.exclusives);
      setTotal(response.contador);
      setLoadingData(false);
    });
  };

  // const addNotification = (record) => {
  //   setIsVisibleModal(true);
  //   setModalTitle("Enviar notificación");
  //   setmodalContent(
  //     <EditNotificationExclusiveForm
  //       notification={null}
  //       exclusiveId={record.id}
  //       setIsVisibleModal={setIsVisibleModal}
  //       setReloadNotifications={setReloadNotifications}
  //       buttonName="Enviar notificación"
  //       addSlag={"add"}
  //     />
  //   );
  // };

  const addExclusive = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo artículo");
    setmodalContent(
      <EditExclusiveForm
        exclusive={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadExclusives={setReloadExclusives}
        buttonName="Agregar artículo"
        addSlag={"add"}
        date={moment()}
      />
    );
  };

  const editExclusive = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar artículo");
    setmodalContent(
      <EditExclusiveForm
        exclusive={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadExclusives={setReloadExclusives}
        buttonName="Editar artículo"
        addSlag={"edit"}
        date={moment()}
      />
    );
  };

  const deleteExclusive = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar artículo");
    setmodalContent(
      <EditExclusiveForm
        exclusive={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadExclusives={setReloadExclusives}
        buttonName="Eliminar artículo"
        addSlag={"delete"}
        date={moment()}
      />
    );
  };

  const columns = [
    {
      title: "Tìtulo",
      width: 150,
      dataIndex: "title",
      key: "title",
      fixed: "left",
    },
    {
      title: "Categoría",
      width: 100,
      dataIndex: ["type", "name"],
      key: "roleId",
    },
    {
      title: "Resumen",
      width: 200,
      dataIndex: "summary",
      key: "summary",
      //fixed: "left",
    },

    {
      title: "Estado",
      width: 20,
      dataIndex: "status",
      key: "status",
      render: (_, { status, date }) => (
        <>
          {moment(date).diff(moment()) < 0 ? (
            <Tag icon={<EyeInvisibleFilled />}>Caducado</Tag>
          ) : status === true ? (
            <Tag icon={<EyeFilled />} color="success">
              Visible
            </Tag>
          ) : (
            <Tag icon={<EyeInvisibleFilled />} color="warning">
              Oculto
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Fecha creación del artículo",
      width: 100,
      dataIndex: "createdAt",
      key: "createdAt",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    },
    {
      title: "Fecha visualización del artículo",
      width: 100,
      dataIndex: "date",
      key: "date",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD HH:mm")}</span>,
    },
    {
      title: "Autor",
      width: 100,
      //dataIndex: ["user", "name"],
      key: "autor",
      render: (text, record) => (
        <span>
          {record.user ? record.user.name : ""}{" "}
          {record.user ? record.user.lastname : ""}
        </span>
      ),
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => editExclusive(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => deleteExclusive(record)}
          >
            <DeleteOutlined />
          </Button>
          {/* <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => addNotification(record)}
          >
            <NotificationOutlined />
          </Button> */}
          {/* <Button
            size="small"
            type="default"
            onClick={() => navigate(`/admin/exclusives/details/${record.id}`)}
          >
            <FolderOpenOutlined />
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div className="exclusive">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Notificaciones exclusivas</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addExclusive}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={exclusive}
        loading={loadingData}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <p>Destinatarios:</p>
              <p style={{ margin: 0 }}>
                {record.users && record.users.map
                  ? record.users.map((item) => (
                      <Tag color="green" key={item.id}>
                        {item.name} {item.lastname}
                      </Tag>
                    ))
                  : ""}
              </p>
            </>
          ),
          rowExpandable: (record) => record.users.length > 0,
        }}
        rowKey="id"
        pagination={{
          total: total,
          pageSize: pagesize,
          onChange: (page, pageSize) => {
            fetchRecords(page, pageSize);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen artículos"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={1200}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
