import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateUploadApi,
  AddUploadApi,
  DeleteUploadApi,
  uploadVideoApi,
} from "../../../../api/upload.api";

import "./EditUploadForm.scss";
import { basePath } from "../../../../api/config";
import useAuth from "../../../../hooks/useAuth";

export default function EditUploadForm(props) {
  const {
    upload,
    setIsVisibleModal,
    setReloadUploads,
    buttonName,
    addSlag,
    setUrl,
    uploadType,
    range,
  } = props;
  const [uploadData, setUploadData] = useState({});
  const { user, isLoading } = useAuth();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }
    setFileList([]);

    setUploadData({
      name: "",
      userId: null,
    });
  };

  useEffect(() => {
    if (upload) {
      setUploadData({
        id: upload.id,
        url: upload.url,
        name: upload.name,
        userId: upload.userId,
      });
    } else {
      setUploadData({
        id: null,
        url: "",
        name: "",
        userId: null,
      });
    }
  }, [upload]);

  const addUpdateUpload = (e) => {
    console.log("add");
    e.preventDefault();
    const token = getAccessTokenApi();
    let uploadUpdate = uploadData;
    if (fileList.length === 0 && (addSlag === "add" || addSlag === "edit")) {
      notification["error"]({
        message: "Es necesario cargar un archivo",
      });
      return;
    }

    if (!uploadUpdate.name && (addSlag === "add" || addSlag === "edit")) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      uploadUpdate.userId = user.id;
      setLoading(true);
      uploadVideoApi(token, fileList[0]).then((result) => {
        //console.log(result);
        uploadUpdate.url = `${basePath}/${result.name}`;
        //setUrl(`${basePath}/${result.name}`);

        AddUploadApi(token, uploadUpdate).then((result1) => {
          notification["success"]({
            message: result1.message,
          });
          //setReloadUploads(true);
          setReloadUploads({ reloadUploads: true });
          //setIsVisibleModal(false);
          setIsVisibleModal({ isVisibleUploadModal: false });
          resetForm();
          setLoading(false);
          if (uploadType === "application/pdf") {
            setUrl(
              { url: `${basePath}/${result.name}`, value: uploadUpdate.name },
              range
            );
          } else {
            setUrl(`${basePath}/${result.name}`, range);
          }
        });
      });
    } else if (addSlag === "edit") {
      setLoading(true);
      uploadUpdate.userId = user.id;
      uploadVideoApi(token, fileList[0]).then((result) => {
        uploadUpdate.url = `${basePath}/${result.name}`;
        UpdateUploadApi(token, uploadUpdate, upload.id).then((result) => {
          notification["success"]({
            message: result.message,
          });
          //setReloadUploads(true);
          setReloadUploads({ reloadUploads: true });
          //setIsVisibleModal(false);
          setIsVisibleModal({ isVisibleUploadModal: false });
          setUploadData({
            ...uploadData,
          });
          setLoading(false);
          setUrl(`${basePath}/${result.name}`, range);
        });
      });
    } else {
      DeleteUploadApi(token, upload.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        //setReloadUploads(true);
        setReloadUploads({ reloadUploads: true });
        //setIsVisibleModal(false);
        setIsVisibleModal({ isVisibleUploadModal: false });
        setLoading(false);
      });
    }
  };

  const propsuploads = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
    maxCount: 1,
    accept: uploadType,
  };

  return (
    <div className="edit-upload-form">
      {/* <form className="form-edit" onSubmit={addUpdateUpload}> */}
      <form className="form-edit">
        <Row gutter={24}>
          {(addSlag === "add" || addSlag === "edit") && (
            <Col span={24}>
              <Upload maxCount={1} {...propsuploads}>
                <Button icon={<UploadOutlined />}>
                  Selecciona el archivo para subirlo al servidor
                </Button>
              </Upload>
            </Col>
          )}
          {/* <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Url"
                value={uploadData.url}
                onChange={(e) =>
                  setUploadData({ ...uploadData, url: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={uploadData.name}
                onChange={(e) =>
                  setUploadData({ ...uploadData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            //htmlType="submit"
            //className="btn-submit"
            onClick={addUpdateUpload}
            loading={loading}
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
