//export const basePath = "https://api-ci.aplios.software";

//prod
//export const basePath = "https://otrapi.aplios.net";
//prod 2

export const basePath = "https://api.otradmin.com";
//dev
//export const basePath = "http://localhost:3981";

export const apiVersion = "v1";

//prod
export const apiKeyGoogle = "AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o";
//dev
//export const apiKeyGoogle = "AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8";

export const appIdOnesignal = "0dc66519-5bf3-4a97-9eae-45b3d1defd68";

export const keyOnesignal = "MmZhNzJmMjUtNTc1MC00MzVlLWE3ZDYtNjUwYmI4N2FmNWUw";
