import React from "react";
import { Layout, Card, Tabs } from "antd";
//import { Redirect } from "react-router-dom";

import Logo from "../../../assets/img/png/logo.png";
//import RegisterForm from "../../../components/Admin/RegisterForm";
import LoginForm from "../../../components/Admin/LoginForm";

import { getAccessTokenApi } from "../../../api/auth";

import "./Condiciones.scss";
//import { Content } from "antd/lib/layout/layout";
import { Navigate } from "react-router";
import RecoverForm from "../../../components/Admin/RecoverForm";
const { TabPane } = Tabs;

export default function Condiciones() {
  const { Content, Footer } = Layout;
  return (
    <Layout className="condiciones">
      <Content className="condiciones__content">
        <br />
        <br />
        <h1 className="condiciones__content-logo">
          <img src={Logo} alt="CI" />
        </h1>
        <br />
        <br />
        {/* <h2 className="sign-in__content-subtitle">
          Portal de administración Innovando Amazonía
        </h2> */}
        <div className="condiciones__content-title">
          <h1>Términos y Condiciones de Uso de OFF THE RECORD</h1>
        </div>
        <p className="condiciones__content-p">
          <strong>1. Introducción</strong> <br />
          <br />
          Bienvenido a OFF THE RECORD, la aplicación móvil para el acceso a
          noticias locales e internacionales. Estos términos y condiciones
          ("Términos") rigen su acceso y uso de nuestra aplicación y servicios
          asociados. Al descargar o usar la aplicación, usted acepta estar
          vinculado por estos Términos.
          <br />
          <br />
          <br /> <strong>2. Servicios Ofrecidos</strong>
          <br />
          <br /> OFF THE RECORD proporciona acceso a una variedad de contenidos
          noticiosos, incluyendo pero no limitado a artículos, fotografías,
          videos, y comentarios, tanto de fuentes locales como internacionales.
          <br />
          <br />
          <br />
          <strong>3. Derechos de Propiedad Intelectual</strong>
          <br />
          <br /> Todo el contenido presente en OFF THE RECORD es propiedad de
          sus respectivos dueños o está licenciado a nosotros y está protegido
          por leyes de derechos de autor y marcas registradas. El contenido no
          puede ser usado, copiado, reproducido, distribuido, transmitido,
          exhibido, vendido, licenciado, o de otra manera explotado para ningún
          otro propósito sin el consentimiento previo por escrito de los
          respectivos propietarios.
          <br />
          <br />
          <br /> <strong>4. Uso de la Aplicación</strong>
          <br />
          <br /> Usted se compromete a usar OFF THE RECORD únicamente para fines
          personales y no comerciales. Está prohibido el uso de la aplicación
          para actividades ilegales o para transmitir contenido que sea ilegal,
          difamatorio, ofensivo o que infrinja derechos de terceros.
          <br />
          <br />
          <br /> <strong>5. Cuentas de Usuario</strong>
          <br />
          <br /> Algunas funciones de la aplicación requieren la creación de una
          cuenta. Usted es responsable de mantener la confidencialidad de su
          cuenta y contraseña y de todas las actividades que ocurran bajo su
          cuenta.
          <br />
          <br />
          <br /> <strong>6. Privacidad</strong>
          <br />
          <br /> Su privacidad es importante para nosotros. Nuestra política de
          privacidad explica cómo recopilamos, usamos y protegemos su
          información personal. Al usar [Nombre de la Aplicación], usted acepta
          la recopilación y uso de su información de acuerdo con nuestra
          política de privacidad.
          <br />
          <br />
          <br /> <strong>7. Cambios en los Términos</strong>
          <br />
          <br /> Nos reservamos el derecho de modificar estos Términos en
          cualquier momento. Los cambios entrarán en vigor inmediatamente
          después de su publicación en la aplicación. Su uso continuado de la
          aplicación constituye su aceptación de tales cambios.
          <br />
          <br />
          <br /> <strong>8. Limitación de Responsabilidad</strong>
          <br />
          <br /> OFF THE RECORD no será responsable por daños indirectos,
          incidentales, especiales, consecuentes o ejemplares, incluyendo pero
          no limitado a, pérdidas de beneficios, datos u otras pérdidas
          intangibles resultantes del uso de la aplicación o de la incapacidad
          de usarla.
          <br />
          <br />
          <br /> <strong>9. Ley Aplicable y Jurisdicción</strong>
          <br />
          <br /> Estos Términos se regirán e interpretarán de acuerdo con las
          leyes de ECUADOR. Cualquier disputa relacionada con estos Términos
          estará sujeta a la jurisdicción exclusiva de los tribunales de
          ECUADOR. <br />
          <br />
          <br />
          <strong>10. Contacto</strong>
          <br />
          <br /> Para cualquier pregunta o consulta sobre estos Términos, por
          favor contáctenos en info@laposta.ec. <br />
          <br />
          <br />
          <strong>Fecha de Última Actualización: 05/02/2024</strong>
          <br />
          <br />
        </p>
      </Content>
      {/* <Footer className="sign-in__footer">
        © Copyright IICA - Innovando Amazonía. <br></br>All Rights Reserved |
        Versión 1.0
      </Footer> */}
    </Layout>
  );
}
