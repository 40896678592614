import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Tabs,
  PageHeader,
  Switch,
} from "antd";
import { useDropzone } from "react-dropzone";
import noAvatar from "../../../../assets/img/png/no-avatar.png";
import { SaveOutlined, LockOutlined } from "@ant-design/icons";
import moment from "moment";

import { UpdateUserApi } from "../../../../api/user.api";

import { getAccessTokenApi } from "../../../../api/auth";

import "./EditPasswordForm.scss";

export default function EditPasswordForm(props) {
  const { user, setIsVisibleModal, setReloadUsers, addSlag } = props;
  const [userData, setUserData] = useState({});
  const [buttonTitle, setbuttonTitle] = useState("");
  const [formPersonal] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const token = getAccessTokenApi();
  const [role, setRole] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingsMap, setLoadingsMap] = useState(true);

  useEffect(() => {
    if (user) {
      setbuttonTitle("Guardar cambios");
      setUserData(user);
      formPersonal.setFieldsValue(user);
      if (loadingsMap) {
        setLoadingsMap(false);
      }
      setRole(user.roleId);
      setLoadingData(false);
    }
  }, [user]);
  //console.log(userData.admission == null);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      ...values,
    };
    setUserData(userAdd);
    console.log(userAdd);

    UpdateUserApi(token, userAdd, user.id).then((result) => {
      if (result.status) {
        setUserData(result.user);
        notification["success"]({
          message: "La información se ha actualizado correctamente",
        });
        setReloadUsers(true);

        setLoadings(false);
        setIsVisibleModal(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });

    // console.log("Success values:", values);
    // console.log("Success:", userData);
    //next();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revisa los errores en la información personal",
      duration: 3,
    });
  };

  return (
    <div className="edit-user-form">
      <Form
        form={formPersonal}
        name="personal"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <PageHeader
              className="site-page-header header-left"
              // onBack={() => null}
              style={{ textAlign: "left", justifyContent: "start" }}
              title={
                <>
                  <Avatar
                    icon={<LockOutlined />}
                    style={{ backgroundColor: "#f42c1e", color: "white" }}
                  />
                  <span> Contraseña</span>
                </>
              }
              // subTitle="This is a subtitle"
            />
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Contraseña"
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la contraseña",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Contraseña"
                    type="password"
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Repetir contraseña"
                  name={"passwordrepeat"}
                  rules={[
                    {
                      required: true,
                      message: "Ingrese la contraseña",
                    },
                  ]}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    //prefix={<UserOutlined />}
                    placeholder="Repetir contraseña"
                    type="password"
                    //value={userData.name}
                    onChange={
                      (e) => {}
                      //setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  label="Solicitar cambio de contraseña"
                  name={"changepass"}
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>

            <br></br>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button
                    className="btnBig"
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loadings}
                  >
                    Guardar <SaveOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);
  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpg, image/jpeg,image/png",
    noKeyboard: true,
    onDrop,
  });

  return (
    <div className="upload-avatar" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Avatar size={150} src={noAvatar} />
      ) : (
        <Avatar size={150} src={avatarUrl ? avatarUrl : noAvatar} />
      )}
    </div>
  );
}
