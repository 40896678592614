import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  notification as notificationant,
  Divider,
  Radio,
} from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateNotificationApi,
  AddNotificationApi,
  DeleteNotificationApi,
} from "../../../../api/notification.api";

import { notificationApi } from "../../../../api/onesignal.api";

import { getLectorsApi } from "../../../../api/user.api";

//import useAuth from "../../../../../hooks/useAuth";
import "./EditNotificationForm.scss";
//import SelectKnowledgeForm from "../../../../../components/Admin/Knowledge/SelectKnowledgeForm";
import moment from "moment";
import SelectArticleForm from "../../../../components/Admin/Article/SelectArticleForm";

import useAuth from "../../../../hooks/useAuth";

export default function EditNotificationForm(props) {
  const {
    notification,
    setIsVisibleModal,
    setReloadNotifications,
    buttonName,
    addSlag,
  } = props;
  const { user, isLoading } = useAuth();

  const [notificationData, setNotificationData] = useState({});
  const token = getAccessTokenApi();
  const [formNotification] = Form.useForm();
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const urlclient = window.location.origin;
  const [article, setArticle] = useState();
  const [userData, setUserData] = useState({});
  const [exclusiveusers, setExclusiveusers] = useState([]);
  // const [tags, setTags] = useState(["Unremovable", "Tag 2", "Tag 3"]);
  //const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [userDataAll, setUserDataAll] = useState([]);
  const [premium, setPremium] = useState("0");
  // const pathname = window.location.pathname;
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;

  useEffect(() => {
    if (notification) {
      if (notification.date && notification.date !== undefined) {
        notification.date = moment(notification.date);
      }
      // if (notification.key !== null) {
      //   var tagsload = notification.key.split(",");
      //   console.log(notification.key);
      //   console.log(tagsload);
      //   if (tagsload.length > 0) {
      //     setTags(tagsload);
      //   }
      // }
      // setImageUrl(notification.photo);

      //formNotification.setFieldsValue(notification);
      const data = [];
      let users = notification.users;
      console.log(users);
      // users.map((user) => data.push(user.id));
      users.map((user) => data.push(user.id));
      notification.users = data;
      formNotification.setFieldsValue(notification);
      console.log(data);
      setExclusiveusers(data);
    } else {
      //setImageUrl(null);
      //setTags([]);
      formNotification.setFieldsValue({
        message: null,
        articleId: null,
        userId: null,
        users: [],
      });
    }

    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, [notification]);

  useEffect(() => {
    // getLectorsApi(token).then((response) => {
    //   const data = [];

    //   let users = response.users;
    //   users.map((user) =>
    //     data.push({
    //       label: `${user.name} ${user.lastname} (${
    //         user.premium ? "Premium" : "Estándar"
    //       })`,
    //       value: user.id,
    //       email: user.email,
    //     })
    //   );
    //   console.log(data);
    //   setUserData(data);
    //   setLoading(false);
    // });
    // getLectorsApi(token, false).then((response) => {
    //   const data = [];
    //   const data1 = [];
    //   let users = response.users;
    //   users.map((user) =>
    //     data.push({
    //       label: `${user.name} ${user.lastname} (${
    //         user.premium ? "Premium" : "Estándar"
    //       })`,
    //       value: user.id,
    //       email: user.email,
    //     })
    //   );
    //   users.map((user) => data1.push(user.id));
    //   console.log(data);
    //   setUserData(data);
    //   setUserDataAll(data1);
    //   setLoading(false);
    // });

    if (addSlag === "add") {
      //setImageUrl(null);
      //setTags([]);
      formNotification.setFieldsValue({
        message: null,
        articleId: null,
        userId: null,
        users: [],
      });
    }
    // getEntrepreneurshipByIdApi(token, user.id).then((response) => {
    //   setEntrepreneurshipData(response.entrepreneurship);
    // });
  }, []);

  useEffect(() => {
    getLectorsApi(token, premium).then((response) => {
      const data = [];
      const data1 = [];
      let users = response.users;
      users.map((user) =>
        data.push({
          label: `${user.name} ${user.lastname} (${
            user.premium ? "Premium" : "Estándar"
          })`,
          value: user.id,
          email: user.email,
        })
      );
      users.map((user) => data1.push(user.id));
      console.log(data);
      setUserData(data);
      setUserDataAll(data1);
      setLoading(false);
    });

    if (addSlag === "add") {
      formNotification.setFieldsValue({
        users: [],
      });
    }
  }, [premium]);

  const onFinish = (values) => {
    setSending(true);
    const token = getAccessTokenApi();
    if (addSlag === "add") {
      AddNotificationApi(token, {
        ...values,
        userId: user.id,
        //description: editor.editorHtml,
      }).then((result) => {
        console.log(result);

        const data = [];
        let users = values.users;
        console.log(userData);
        users.map((user) =>
          data.push(
            userData.find((obj) => {
              return obj.value === user;
            }).email
          )
        );
        notificationApi(data, values.message, "article", values.articleId).then(
          (result) => {
            console.log(result);
          }
        );

        if (result.status) {
          notificationant["success"]({
            message: result.message,
          });
          formNotification.setFieldsValue({
            message: null,
            articleId: null,
            userId: null,
            users: [],
          });
          setSending(false);
          setReloadNotifications(true);
          setIsVisibleModal(false);
        } else {
          setSending(false);
          notificationant["error"]({
            message: result.message,
          });
        }

        //resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateNotificationApi(
        token,
        {
          ...values,
          userId: user.id,
        },
        notification.id
      ).then((result) => {
        notificationant["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadNotifications(true);
        setIsVisibleModal(false);
        setNotificationData({
          ...notificationData,
        });
      });
    } else {
      DeleteNotificationApi(token, notification.id).then((result) => {
        notificationant["success"]({
          message: result.message,
        });
        setSending(false);
        setReloadNotifications(true);
        setIsVisibleModal(false);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    console.log(imageUrl);
    //message(errorInfo);
    notificationant["error"]({
      message: "Revisa los errores en la información ingresada",
      duration: 3,
    });
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const onCheckAllChange = (e) => {
    console.log(userDataAll);
    //setCheckedList(e.target.checked ? userDataAll : []);
    //setExclusiveusers(e.target.checked ? userDataAll : []);
    if (e.target.checked) {
      formNotification.setFieldsValue({ users: userDataAll });
    } else {
      formNotification.setFieldsValue({ users: [] });
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onChange = (list) => {
    console.log(list);
    //setCheckedList(list);
    setIndeterminate(!!list.length && list.length < userDataAll.length);
    setCheckAll(list.length === userDataAll.length);
  };
  const onChangePremium = ({ target: { value } }) => {
    setPremium(value);
    setIndeterminate(false);
    setCheckAll(false);
  };

  return (
    <div className="edit-notification-form">
      <Form
        form={formNotification}
        name="notification"
        className="form-edit"
        //onSubmit={addUpdateNotification}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: false }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16} lg={18} xl={18}>
            <Form.Item
              label="Mensaje"
              name={"message"}
              rules={[
                {
                  required: true,
                  message: "Ingrese el mensaje",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Input
                //prefix={<UserOutlined />}
                placeholder="Mensaje"
                maxLength={100}
                //value={userData.name}
                onChange={
                  (e) => {}
                  //setUserData({ ...userData, name: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <SelectArticleForm
              atributoData={article}
              setAtributoData={setArticle}
            ></SelectArticleForm>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {/* <Form.Item
              label="Destinatarios"
              name={"users"}
              rules={[
                {
                  required: true,
                  message: "Seleccione al menos un destinatario",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
            >
              <Checkbox.Group
                loading={loading}
                options={userData}
                initialValue={exclusiveusers}
              />
            </Form.Item> */}
            <br />
            <div class="ant-col ant-col-24 ant-form-item-label">
              <label
                for="exclusive_users"
                class="ant-form-item-required"
                title="Destinatarios"
              >
                Destinatarios (Se muestra solo premium)
              </label>
            </div>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                {/* Mostrar solo premium <Switch onChange={onChangePremium} /> */}
                <Radio.Group
                  options={[
                    {
                      label: "Todos",
                      value: "0",
                    },
                    {
                      label: "Premium",
                      value: "1",
                    },
                    {
                      label: "Estándar",
                      value: "2",
                    },
                  ]}
                  onChange={onChangePremium}
                  value={premium}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Seleccionar todos
                </Checkbox>
              </Col>
            </Row>
            <Divider />
            <Form.Item
              // label={
              //   <>
              //     Destinatarios
              //     <br />
              //     <Checkbox
              //       indeterminate={indeterminate}
              //       onChange={onCheckAllChange}
              //       checked={checkAll}
              //     >
              //       Todos
              //     </Checkbox>
              //   </>
              // }
              name={"users"}
              rules={[
                {
                  required: true,
                  message: "Seleccione al menos un destinatario",
                },
              ]}
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 24 }}
              initialValue={exclusiveusers}
              //value={checkedList}
            >
              {/* <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox> */}
              <Checkbox.Group
                //loading={loading}
                options={userData}
                onChange={onChange}
                //value={checkedList}
                //onChange={onChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
            loading={sending}
          >
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
