import React, { useState, useEffect } from "react";
import { Breadcrumb, Empty, DatePicker, Row, Col, Form } from "antd";
import { Table } from "ant-table-extensions";
//import { Bar } from "@ant-design/charts";
import { getAccessTokenApi } from "../../../api/auth";
import SelectArticleForm from "../../../components/Admin/Article/SelectArticleForm/SelectArticleFormAll";
import SelectUserForm from "../../../components/Admin/Users/SelectUserForm";

//import SelectRoleOneForm from "../../../components/Admin/Role/SelectRoleForm/SelectRoleOneForm";
//import SelectCommunityOneForm from "../../../components/Admin/Community/SelectCommunityForm/SelectCommunityOneForm";
//import SelectProvinceAllForm from "../../../components/Admin/Province/SelectProvinceForm/SelectProvinceAllForm";
import "./Reports.scss";
//import SelectMultiFinca from "../../../components/Admin/Finca/SelectMultiFinca";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import moment from "moment";
import { getLogsApi } from "../../../api/log.api";

export default function Lectura() {
  const token = getAccessTokenApi();
  const [logs, setLogs] = useState([]);
  const [article, setArticle] = useState(0);
  const [user, setUser] = useState(0);
  const [start, setStart] = useState("0");
  const [end, setEnd] = useState("0");
  //const [province, setProvince] = useState(0);
  //const [role, setRole] = useState(0);
  //const [community, setCommunity] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    getLogsApi(token, article, user, start, end).then((response) => {
      console.log(response.data);
      setLogs(response.data);
      setLoadingData(false);
    });
  }, [token, article, user, start, end]);

  const columns = [
    {
      title: "Fecha",
      width: 80,
      dataIndex: "date",
      key: "date",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD HH:ss")}</span>,
    },
    {
      title: "Artículo",
      width: 300,
      dataIndex: ["article", "title"],
      key: "articleId",
      //fixed: "left",
    },
    {
      title: "Nombre del lector",
      width: 80,
      dataIndex: ["user", "name"],
      key: "userId",
      //fixed: "left",
    },
    {
      title: "Apellido del lector",
      width: 80,
      dataIndex: ["user", "lastname"],
      key: "userId",
      //fixed: "left",
    },
    // {
    //   title: "Lector",
    //   width: 130,
    //   //dataIndex: ["user", "name"],
    //   key: "user",
    //   render: (text, record) => (
    //     <span>
    //       {record.user ? record.user.name : ""}{" "}
    //       {record.user ? record.user.lastname : ""}
    //     </span>
    //   ),
    // },
    {
      title: "Email",
      width: 130,
      dataIndex: ["user", "email"],
      key: "userId",
      //fixed: "left",
    },
  ];

  const fields = {
    date: {
      header: "Fecha",
      formatter: (_fieldValue, record) => {
        return moment(record?.date).format("YYYY-MM-DD HH:ss");
      },
    },
    article: {
      header: "Artículo",
      formatter: (_fieldValue, record) => {
        return record?.article?.title;
      },
    },
    user: {
      header: "Lector",
      formatter: (_fieldValue, record) => {
        return record?.user?.name + " " + record?.user?.lastname;
      },
    },
    email: {
      header: "Email",
      formatter: (_fieldValue, record) => {
        return record?.user?.email;
      },
    }, // Country column will get 'Your Country' as header
  };

  //   const fields: [ {
  //     ci: "CI",
  //     name: "Nombres",
  //     lastname: "Apellidos",
  //     usuario: {
  //       header: "Usuario",
  //       formatter: (_fieldValue, record) => {
  //         return "COMPLETO";
  //       },
  //     },
  //     emprendimiento: {
  //       header: "Emprendimiento",
  //       formatter: (_fieldValue, record) => {
  //         if (record.entrepreneurships.length > 0) {
  //           return "COMPLETO";
  //         } else {
  //           return "NO REGISTRADO";
  //         }
  //       },
  //     },
  //     haves: {
  //       header: "Yo tengo",
  //       formatter: (_fieldValue, record) => {
  //         if (record.entrepreneurships.length > 0) {
  //           return record.entrepreneurships[0].haves.length + " REGISTROS";
  //         } else {
  //           return "SIN EMPRESA";
  //         }
  //       },
  //     },
  //     needs: {
  //       header: "Yo necesito",
  //       formatter: (_fieldValue, record) => {
  //         if (record.entrepreneurships.length > 0) {
  //           return record.entrepreneurships[0].needs.length + " REGISTROS";
  //         } else {
  //           return "SIN EMPRESA";
  //         }
  //       },
  //     },
  //     encuesta: {
  //       header: "Encuesta",
  //       formatter: (_fieldValue, record) => {
  //         if (record.entrepreneurships.length > 0) {
  //           if (record.entrepreneurships[0].question1 !== null) {
  //             return "COMPLETO";
  //           }
  //           return "SIN ENCUESTA";
  //         } else {
  //           return "SIN EMPRESA";
  //         }
  //       },
  //     },
  //   }
  // ];

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setStart(dateString[0].length > 0 ? dateString[0] : "0");
    setEnd(dateString[1].length > 0 ? dateString[1] : "0");
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Reportes</Breadcrumb.Item>
        <Breadcrumb.Item>Lectura de artículos</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <br />
        <h2>Reporte de lectura de artículos</h2>
        <Form
          name="filters"
          initialValues={{ remember: false }}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={12} xl={12}>
              <SelectArticleForm
                atributoData={article}
                setAtributoData={setArticle}
              ></SelectArticleForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectUserForm
                atributoData={user}
                setAtributoData={setUser}
              ></SelectUserForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <div class="ant-row ant-form-item-row">
                <div class="ant-col ant-col-24 ant-form-item-label">
                  <label
                    for="filters_articleId"
                    class="ant-form-item-required"
                    title="Rango de fechas"
                  >
                    Rango de fechas
                  </label>
                </div>
                <div class="ant-col ant-col-24 ant-form-item-label">
                  <RangePicker onChange={onChange} />
                </div>
              </div>
            </Col>

            {/* <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectAllCityForm
                atributoData={city}
                setAtributoData={setCity}
                provinceData={province}
              ></SelectAllCityForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectRoleOneForm
                atributoData={role}
                setAtributoData={setRole}
              ></SelectRoleOneForm>
            </Col>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
              <SelectCommunityOneForm
                atributoData={community}
                setAtributoData={setCommunity}
              ></SelectCommunityOneForm>
            </Col> */}
          </Row>
        </Form>
        {/* <Button type="primary" onClick={() => exportTable()}>
          Exportar <ExportOutlined />
        </Button>
        <br />
        <br /> */}
        <Table
          columns={columns}
          dataSource={logs}
          loading={loadingData}
          rowKey="id"
          scroll={{ x: 1200 }}
          size="small"
          //onChange={onChange}
          exportable
          searchable
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} de ${total} registros`,
          }}
          searchableProps={{
            inputProps: {
              placeholder: "Buscar",
            },
          }}
          exportableProps={{
            fields,
            // showColumnPicker: true,
            fileName: "Estado de registros",
            btnProps: {
              type: "primary",
              icon: <ExportOutlined />,
              children: <span>Exportar</span>,
            },
            children: <span>Exportar</span>,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existen registros"
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
