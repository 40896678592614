import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
// import { useNavigate } from "react-router-dom";

import { Table, Button, Empty, Breadcrumb, Affix, Tag, Image } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  // FolderOpenOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  NotificationOutlined,
} from "@ant-design/icons/";

import "./Articulos.scss";
import { getArticlesPageApi } from "../../../api/article.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditArticleForm from "../../../components/Admin/Article/EditArticleForm";
import moment from "moment";
import EditNotificationArticleForm from "../../../components/Admin/Notification/EditNotificationForm/EditNotificationArticleForm";

import interest from "../../../assets/img/png/nointeresa.png";
import deep from "../../../assets/img/png/profundizar.png";
import reunion from "../../../assets/img/png/reunion.png";
import info from "../../../assets/img/png/informacion.png";

export default function Article() {
  const [article, setArticle] = useState([]);
  const token = getAccessTokenApi();
  const [reloadArticles, setReloadArticles] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [reloadNotifications, setReloadNotifications] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageinit, setPageinit] = useState(1);
  const [pagesize, setPagesize] = useState(5);
  // const navigate = useNavigate();
  // const url = window.location.origin;

  useEffect(() => {
    // getArticlesApi(token).then((response) => {
    //   setArticle(response.articles);
    //   setLoadingData(false);
    // });
    fetchRecords(pageinit, pagesize);

    setReloadArticles(false);
  }, [token, reloadArticles]);

  const fetchRecords = (page_, pageSize_) => {
    setPageinit(page_);
    setPagesize(pageSize_);
    setLoadingData(true);
    getArticlesPageApi(token, pageSize_, page_).then((response) => {
      setArticle(response.articles);
      setTotal(response.contador);
      setLoadingData(false);
    });
  };

  const addNotification = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Enviar notificación");
    setmodalContent(
      <EditNotificationArticleForm
        notification={null}
        articleId={record.id}
        setIsVisibleModal={setIsVisibleModal}
        setReloadNotifications={setReloadNotifications}
        buttonName="Enviar notificación"
        addSlag={"add"}
      />
    );
  };

  const addArticle = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo artículo");
    setmodalContent(
      <EditArticleForm
        article={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadArticles={setReloadArticles}
        buttonName="Agregar artículo"
        addSlag={"add"}
        date={moment()}
      />
    );
  };

  const editArticle = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar artículo");
    setmodalContent(
      <EditArticleForm
        article={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadArticles={setReloadArticles}
        buttonName="Editar artículo"
        addSlag={"edit"}
        date={moment()}
      />
    );
  };

  const deleteArticle = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar artículo");
    setmodalContent(
      <EditArticleForm
        article={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadArticles={setReloadArticles}
        buttonName="Eliminar artículo"
        addSlag={"delete"}
        date={moment()}
      />
    );
  };

  const columns = [
    {
      title: "Fotografía",
      width: 100,
      dataIndex: "photo",
      key: "photo",
      //fixed: "left",
      render: (_, { photo }) => <Image width={100} src={photo} />,
    },
    {
      title: "Tìtulo",
      width: 150,
      dataIndex: "title",
      key: "title",
      //fixed: "left",
    },
    {
      title: "Interacciones",
      key: "extras",
      //fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Tag
            color="#a99215"
            style={{ marginBottom: "3px" }}
            icon={
              <img src={interest} alt="interest" style={{ width: "16px" }} />
            }
            key={"interest" + record.id}
          >
            {" "}
            <b>{record.interest}</b> Interés
          </Tag>
          <Tag
            color="#4096FF"
            style={{ marginBottom: "3px" }}
            icon={<img src={deep} alt="deep" style={{ width: "16px" }} />}
            key={"deep" + record.id}
          >
            {" "}
            <b>{record.deep}</b> Profundizar
          </Tag>

          <Tag
            color="#52C41A"
            style={{ marginBottom: "3px" }}
            icon={<img src={info} alt="info" style={{ width: "16px" }} />}
            key={"info" + record.id}
          >
            {" "}
            <b>{record.information}</b> Información
          </Tag>

          <Tag
            color="#F5222D"
            style={{ marginBottom: "3px" }}
            icon={<img src={reunion} alt="reunion" style={{ width: "16px" }} />}
            key={"reunion" + record.id}
          >
            {" "}
            <b>{record.reunion}</b> Reunión
          </Tag>
        </div>
      ),
    },
    {
      title: "Categoría",
      width: 80,
      dataIndex: ["type", "name"],
      key: "roleId",
    },
    {
      title: "Resumen",
      width: 300,
      dataIndex: "summary",
      key: "summary",
      //fixed: "left",
    },

    {
      title: "Estado",
      width: 70,
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status === true ? (
            <Tag icon={<EyeFilled />} color="success">
              Visible
            </Tag>
          ) : (
            <EyeInvisibleFilled />
          )}
        </>
      ),
    },
    {
      title: "Palabras clave",
      width: 120,
      dataIndex: "key",
      key: "key",
      //fixed: "left",
      render: (_, { key }) => (
        <>
          {key !== null &&
            key.split(",").map((tag) => {
              return <Tag key={tag}>{tag}</Tag>;
            })}
        </>
      ),
    },
    {
      title: "Fecha",
      width: 90,
      dataIndex: "date",
      key: "date",
      //fixed: "left",
      render: (text) => <span> {moment(text).format("YYYY-MM-DD")}</span>,
    },
    {
      title: "Autor",
      width: 100,
      //dataIndex: ["user", "name"],
      key: "autor",
      render: (text, record) => (
        <span>
          {record.user ? record.user.name : ""}{" "}
          {record.user ? record.user.lastname : ""}
        </span>
      ),
    },

    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => editArticle(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => deleteArticle(record)}
          >
            <DeleteOutlined />
          </Button>
          <Button
            size="small"
            type="default"
            className="margin-right-minimun"
            onClick={() => addNotification(record)}
          >
            <NotificationOutlined />
          </Button>
          {/* <Button
            size="small"
            type="default"
            onClick={() => navigate(`/admin/articles/details/${record.id}`)}
          >
            <FolderOpenOutlined />
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <div className="article">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Artículos</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addArticle}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={article}
        loading={loadingData}
        rowKey="id"
        pagination={{
          total: total,
          pageSize: pagesize,
          onChange: (page, pageSize) => {
            fetchRecords(page, pageSize);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen artículos"
            />
          ),
        }}
        scroll={{
          x: 1600,
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={1200}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
